import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "guardianForm",
    "bookingForMyself",
    "bookingAsGuardian",
    "purchaserForm",
    "firstName",
    "lastName",
    "cellNumber",
    "cellNumberLabel",
    "genderMale",
    "genderFemale",
    "genderNonBinary",
    "studentEmail",
    "studentEmailLabel",
    "guardianFirstName",
    "guardianLastName",
    "guardianCellNumber",
    "guardianCellNumberLabel",
    "guardianEmail",
    "guardianEmailLabel",
    "purchaserFirstName",
    "purchaserLastName",
    "purchaserCellNumber",
    "purchaserEmail",
  ];

  connect() {
    setTimeout(() => {
      this.changeWhichFieldsAreRequired();
    });
  }

  validateForm() {
    const studentEmailRequired = this.isStudentEmailRequired();
    const guardianEmailRequired = !studentEmailRequired;

    let errors = [];
    if (
      this.firstNameTarget.value == undefined ||
      this.firstNameTarget.value == ""
    ) {
      errors.push("First name is required");
    }
    if (
      this.lastNameTarget.value == undefined ||
      this.lastNameTarget.value == ""
    ) {
      errors.push("Last name is required");
    }
    if (
      studentEmailRequired &&
      (this.cellNumberTarget.value == undefined ||
        this.cellNumberTarget.value == "")
    ) {
      errors.push("Cell number is required");
    }
    if (
      studentEmailRequired &&
      (this.studentEmailTarget.value == undefined ||
        this.studentEmailTarget.value == "")
    ) {
      errors.push("Email is required");
    } else if (this.studentEmailTarget.value) {
      // check if email is valid via a regex
      const email = this.studentEmailTarget.value;
      const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      if (!emailRegex.test(email)) {
        errors.push("Email is not in a valid format");
      }
    }

    if (
      this.hasGenderNonBinaryTarget &&
      this.genderMaleTarget.checked === false &&
      this.genderFemaleTarget.checked === false &&
      this.genderNonBinaryTarget.checked === false
    ) {
      errors.push("Gender is required");
    } else if (
      !this.hasGenderNonBinaryTarget &&
      this.genderMaleTarget.checked === false &&
      this.genderFemaleTarget.checked === false
    ) {
      errors.push("Gender is required");
    }

    // if guardianForm is visible, validate guardian form
    if (this.guardianFormTarget.hidden == false) {
      if (
        this.guardianFirstNameTarget.value == undefined ||
        this.guardianFirstNameTarget.value == ""
      ) {
        errors.push("Guardian first name is required");
      }
      if (
        this.guardianLastNameTarget.value == undefined ||
        this.guardianLastNameTarget.value == ""
      ) {
        errors.push("Guardian last name is required");
      }
      if (
        this.guardianCellNumberTarget.value == undefined ||
        this.guardianCellNumberTarget.value == ""
      ) {
        errors.push("Guardian cell number is required");
      }
      if (
        this.guardianEmailTarget.value == undefined ||
        this.guardianEmailTarget.value == ""
      ) {
        errors.push("Guardian email is required");
      } else {
        // check if email is valid via a regex
        const email = this.guardianEmailTarget.value;
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        if (!emailRegex.test(email)) {
          errors.push("Guardian email is not in a valid format");
        }
        // Check guardian email is not the same as student email
        if (this.studentEmailTarget.value == this.guardianEmailTarget.value) {
          errors.push("Student and parent/guardian email cannot be the same");
        }
      }
    }

    let dateOfBirth = this.getSelectedDate(
      "driving_school_booking_date_of_birth"
    );

    if (!this.isValidDate(dateOfBirth)) {
      errors.push("Please enter a valid date of birth.");
    }

    let issueDate = this.getSelectedDate(
      "driving_school_booking_permit_issue_date"
    );

    if (!this.isValidDate(issueDate)) {
      errors.push("Please enter a valid permit issue date.");
    }

    let expiryDate = this.getSelectedDate(
      "driving_school_booking_permit_expiration"
    );

    if (!this.isValidDate(expiryDate)) {
      errors.push("Please enter a valid permit expiry date.");
    }

    // if purchaserForm is visible, validate purchaser form
    if (this.purchaserFormTarget.hidden == false) {
      //purchaser_first_name
      if (
        this.purchaserFirstNameTarget.value == undefined ||
        this.purchaserFirstNameTarget.value == ""
      ) {
        errors.push("Purchaser first name is required");
      }
      if (
        this.purchaserLastNameTarget.value == undefined ||
        this.purchaserLastNameTarget.value == ""
      ) {
        errors.push("Purchaser last name is required");
      }
      if (
        this.purchaserCellNumberTarget.value == undefined ||
        this.purchaserCellNumberTarget.value == ""
      ) {
        errors.push("Purchaser cell number is required");
      }
      if (
        this.purchaserEmailTarget.value == undefined ||
        this.purchaserEmailTarget.value == ""
      ) {
        errors.push("Purchaser email is required");
      }
    }

    return errors;
  }

  getSelectedDate(prefix) {
    var year = document.getElementById(prefix + "_1i").value;
    var month = document.getElementById(prefix + "_2i").value;
    var day = document.getElementById(prefix + "_3i").value;
    return year + "-" + month + "-" + day; // returns a string in "YYYY-MM-DD" format
  }

  isValidDate(dateString) {
    const parts = dateString.split("-"); // Assuming the format is YYYY-MM-DD
    if (parts.length !== 3) {
      return false; // Not a valid format
    }

    const year = parseInt(parts[0], 10);
    const month = parseInt(parts[1], 10) - 1; // Month is 0-indexed in JavaScript Date
    const day = parseInt(parts[2], 10);

    const date = new Date(year, month, day);

    // Check if the date has rolled over to the next month or year, which means the day was invalid
    if (
      date.getFullYear() !== year ||
      date.getMonth() !== month ||
      date.getDate() !== day
    ) {
      return false; // The date rolled over, so it's invalid
    }

    return true;
  }

  completedForm(event) {
    event.preventDefault();

    const errors = this.validateForm();
    const validationBlock = document.getElementById(
      "validation-personal-block"
    );
    const validationMessage = document.getElementById(
      "validation-message-personal-text"
    );

    if (errors.length > 0) {
      validationBlock.hidden = false;
      validationMessage.innerHTML = errors.join("<br>");
      validationMessage.hidden = false;
      return;
    }

    this.element.dispatchEvent(
      new CustomEvent("personalDetailsComplete", {
        detail: {
          formName: "driving-school-admin-booking-form-v2-personal-details",
          lessonDetails: {},
        },
      })
    );
  }

  bookingForSelf(event) {
    this.relationShipToStudentChanged();
  }

  bookingForOther(event) {
    this.relationShipToStudentChanged();
  }

  relationShipToStudentChanged(event) {
    this.changeWhichFieldsAreRequired();
    const age = this.calculateAge();
    const bookingForMyself = this.bookingForMyselfTarget.checked == true;
    const bookingAsGuardian = this.bookingAsGuardianTarget.checked == true;

    if (bookingForMyself == false && bookingAsGuardian == false) {
      this.purchaserFormTarget.hidden = false;
    } else {
      this.purchaserFormTarget.hidden = true;
    }

    // If under 18, show guardian form
    if (age < 18) {
      this.guardianFormTarget.hidden = false;
      return;
    }

    // If booking as guardian, show guardian form
    if (bookingAsGuardian == true) {
      this.guardianFormTarget.hidden = false;
      return;
    }

    // If over 18 and booking for themselves, hide guardian form
    if (bookingForMyself && age >= 18) {
      console.log("over 18 and booking for himself 18");
      this.guardianFormTarget.hidden = true;
      return;
    }

    this.guardianFormTarget.hidden = true;
  }

  changeWhichFieldsAreRequired() {
    const isStudentEmailRequired = this.isStudentEmailRequired();
    const guardianEmailRequired = !isStudentEmailRequired;

    const studentEmailInput = this.studentEmailTarget;
    const studentEmailLabel = this.studentEmailLabelTarget;
    const studentCellNumberInput = this.cellNumberTarget;
    const studentCellNumberLabel = this.cellNumberLabelTarget;

    if (isStudentEmailRequired) {
      studentEmailInput.required = true;
      studentEmailInput.classList.add("isRequiredLocalised");
      studentEmailLabel.classList.add("isRequiredLocalised");
      studentEmailInput.classList.remove("isRecommendedLocalised");
      studentEmailLabel.classList.remove("isRecommendedLocalised");
      studentCellNumberInput.required = true;
      studentCellNumberInput.classList.add("isRequiredLocalised");
      studentCellNumberLabel.classList.add("isRequiredLocalised");
      studentCellNumberInput.classList.remove("isRecommendedLocalised");
      studentCellNumberLabel.classList.remove("isRecommendedLocalised");
    } else {
      studentEmailInput.required = false;
      studentEmailInput.classList.remove("isRequiredLocalised");
      studentEmailLabel.classList.remove("isRequiredLocalised");
      studentEmailInput.classList.add("isRecommendedLocalised");
      studentEmailLabel.classList.add("isRecommendedLocalised");
      studentCellNumberInput.required = false;
      studentCellNumberInput.classList.remove("isRequiredLocalised");
      studentCellNumberLabel.classList.remove("isRequiredLocalised");
      studentCellNumberInput.classList.add("isRecommendedLocalised");
      studentCellNumberLabel.classList.add("isRecommendedLocalised");
    }

    const guardianEmailInput = this.guardianEmailTarget;
    const guardianEmailLabel = this.guardianEmailLabelTarget;
    const guardianCellNumberInput = this.guardianCellNumberTarget;
    const guardianCellNumberLabel = this.guardianCellNumberLabelTarget;

    if (guardianEmailRequired) {
      guardianEmailInput.required = true;
      guardianEmailInput.classList.add("isRequiredLocalised");
      guardianEmailLabel.classList.add("isRequiredLocalised");
      guardianEmailInput.classList.remove("isRecommendedLocalised");
      guardianEmailLabel.classList.remove("isRecommendedLocalised");
      guardianCellNumberInput.required = true;
      guardianCellNumberInput.classList.add("isRequiredLocalised");
      guardianCellNumberLabel.classList.add("isRequiredLocalised");
      guardianCellNumberInput.classList.remove("isRecommendedLocalised");
      guardianCellNumberLabel.classList.remove("isRecommendedLocalised");
    } else {
      guardianEmailInput.required = false;
      guardianEmailInput.classList.remove("isRequiredLocalised");
      guardianEmailLabel.classList.remove("isRequiredLocalised");
      guardianEmailInput.classList.add("isRecommendedLocalised");
      guardianEmailLabel.classList.add("isRecommendedLocalised");
      guardianCellNumberInput.required = false;
      guardianCellNumberInput.classList.remove("isRequiredLocalised");
      guardianCellNumberLabel.classList.remove("isRequiredLocalised");
      guardianCellNumberInput.classList.add("isRecommendedLocalised");
      guardianCellNumberLabel.classList.add("isRecommendedLocalised");
    }


  }

  isStudentEmailRequired() {
    // We previously had this logic:
    const age = this.calculateAge();
    const isAdult = age >= 18;
    if (this.bookingAsGuardianTarget.checked == false) {
      return isAdult;
    }
    return this.bookingAsGuardianTarget.checked != true;
    // Changed because even if adult, but the spouse is booking, we don't need student email
  }

  calculateAge() {
    var dateOfBirth = this.getDateofBirth();
    if (!dateOfBirth) {
      return 0;
    }

    const parts = dateOfBirth.split("-");
    const formattedDateOfBirth = parts.join("/");
    var dob = new Date(formattedDateOfBirth);
    var today = new Date();

    var age = today.getFullYear() - dob.getFullYear();
    var monthDiff = today.getMonth() - dob.getMonth();
    var dayDiff = today.getDate() - dob.getDate();

    if (monthDiff < 0 || (monthDiff === 0 && dayDiff < 0)) {
      age--;
    }

    return age;
  }

  getDateofBirth() {
    const yearElement = document.querySelector('select[id$="_1i"]');
    const monthElement = document.querySelector('select[id$="_2i"]');
    const dayElement = document.querySelector('select[id$="_3i"]');

    if (!yearElement || !monthElement || !dayElement) {
      return null;
    }

    const day = dayElement.options[dayElement.selectedIndex].value;
    const month = monthElement.options[monthElement.selectedIndex].value;
    const year = yearElement.options[yearElement.selectedIndex].value;

    return `${year}-${month}-${day}`;
  }
}

import { Controller } from "@hotwired/stimulus";
import * as Luxon from "luxon";

export default class extends Controller {
  static targets = [
    "nextButton",
    "nextButton2",
    "nextButton3",
    "nextButton4",
    "nextButton5",
    "bookingForm1",
    "bookingForm2",
    "bookingForm3",
    "bookingForm4",
    "bookingForm5",
    "bookingForm6",
    "bookingFormClassroomProgramZipcode",
    "bookingFormClassroomProgramOfficePicker",
    "bookingFormClassroomProgramPicker",
    "bookingFormClassroomProgramPackagePicker",
    "bookingFormClassroomOfficePickerBackButton",
    "bookingFormClassroomProgramPickerBackButton",
    "dayButton",
    "packageForm",
    "packageOption",
    "timeButton",
    "locationItem",
    "spinner",
    "automaticRadio",
    "manualRadio",
    "loadingContainer",
    "potentialSlotsContainer",
    "payLaterAndTrack",
    "saveButton",
    "slotSelectionInstructorId",
    "duplicateEmailAlert",
    "guardianEmailAlreadyTakenAlert",
    "guardianPassword",
    "email",
    "emailLabel",
    "locale",
    "bookingsEnableInitialBehindTheWheel",
  ];

  connect() {
    const enableInitialBehindTheWheel = this.data.get(
      "bookingsEnableInitialBehindTheWheel"
    );
    console.log("enableInitialBehindTheWheel: " + enableInitialBehindTheWheel);

    // We need to decide what to do here,
    // Need to add listeners that trigger this whenever a relevant value changes
    this.set_data_attributes_on_weekview();

    // Needed for the character counter on location notes
    // Used when scheduling a lesson then editing a location via quick look
    this.initialize_note_counter("pick_up_note");
    this.initialize_note_counter("drop_off_note");

    setTimeout(() => {
      this.beginListeningForLocationChanges();

      // Get the myself checkbox
      const someoneElseCheckbox = document.querySelector(
        '[data-val="someoneelse"]'
      );
      // If present and selected, hide the booking-for-other-details div
      if (someoneElseCheckbox && someoneElseCheckbox.checked) {
        this.bookingForOther();
      } else {
        this.bookingForMyself();
      }
    });

    try {
      if (typeof SignaturePad !== "undefined" && SignaturePad) {
        if (this.element.querySelector("#student-signature-canvas")) {
          this.studentSignaturePad = new SignaturePad(
            this.element.querySelector("#student-signature-canvas")
          );
        }
        if (this.element.querySelector("#guardian-signature-canvas")) {
          this.guardianSignaturePad = new SignaturePad(
            this.element.querySelector("#guardian-signature-canvas")
          );
        }
      }
    } catch (error) {
      console.error("Error initializing signature pads: ", error);
    }
  }

  updateContractPressed(event) {
    // submit form

    // disable the button pressed
    event.currentTarget.disabled = true;

    document.getElementById("update-contract-form").submit();
  }

  submit(event) {
    event.preventDefault();

    const errorMessagesPanel = document.getElementById("error-messages-panel");
    if (errorMessagesPanel) {
      errorMessagesPanel.hidden = true;
    }

    // disable the  payment-button button
    const paymentButton = document.getElementById("payment-button");
    paymentButton.disabled = true;

    const spinnerTarget = document.getElementById("payment-button-spinner");
    if (paymentButton) {
      switch (paymentButton.id) {
        case "payment-button":
          if (spinnerTarget != undefined) {
            spinnerTarget.classList.remove("u-hidden");
            spinnerTarget.classList.add("u-inlineBlock");
          }
          break;
        default:
          break;
      }
    }

    const formData = new FormData(event.target);
    const url = event.target.action;
    const method = event.target.method;

    fetch(url, {
      method: method,
      body: formData,
      credentials: "same-origin", // Include cookies
      headers: {
        "X-CSRF-Token": getMetaValue("csrf-token"), // Add Rails CSRF token
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw response;
        }
        return response.json();
      })
      .then((data) => {
        // Here you'll handle the server response.
        // This assumes your server responds with JSON that includes the URL.
        if (data.url) {
          window.location.href = data.url;
        }
      })
      .catch((response) => {
        if (spinnerTarget != undefined) {
          spinnerTarget.classList.add("u-hidden");
          spinnerTarget.classList.remove("u-inlineBlock");
        }
        paymentButton.disabled = false;
        if (response.status === 400) {
          response.json().then((errorData) => {
            if (errorData.error === "slot_taken") {
              // hide us-booking-form-6
              const bookingForm6 = document.getElementById("us-booking-form-6");
              if (bookingForm6) {
                bookingForm6.hidden = true;
              }
              // show us-booking-form-5
              const bookingForm5 = document.getElementById("us-booking-form-5");
              if (bookingForm5) {
                bookingForm5.hidden = false;
              }
              // find error-slot-taken and show it
              const errorSlotTaken =
                document.getElementById("error-slot-taken");
              if (errorSlotTaken) {
                errorSlotTaken.hidden = false;
              }
              // scroll to top
              window.scrollTo(0, 0);
            }
          });
        } else {
          const errorMessagesPanel = document.getElementById(
            "error-messages-panel"
          );
          if (errorMessagesPanel) {
            errorMessagesPanel.hidden = false;
          }
          paymentButton.disabled = false;
          console.error("Error:", response);
          const responseBodyJson = response.json();
          alert("Something has went wrong, please try again later");
        }
      });
  }

  async showLoader() {
    if (this.targets.find("potentialSlotsContainer")) {
      this.targets.find("potentialSlotsContainer").classList.add("hidden");
      this.targets.find("loadingContainer").classList.remove("hidden");
    }
  }

  async hideLoader() {
    if (this.targets.find("potentialSlotsContainer")) {
      this.targets.find("potentialSlotsContainer").classList.remove("hidden");
      this.targets.find("loadingContainer").classList.add("hidden");
    }
  }

  getDateofBirth() {
    const yearElement = document.querySelector('select[id$="_1i"]');
    const monthElement = document.querySelector('select[id$="_2i"]');
    const dayElement = document.querySelector('select[id$="_3i"]');

    if (!yearElement || !monthElement || !dayElement) {
      return null;
    }

    const day = dayElement.options[dayElement.selectedIndex].value;
    const month = monthElement.options[monthElement.selectedIndex].value;
    const year = yearElement.options[yearElement.selectedIndex].value;

    return `${year}-${month}-${day}`;
  }

  calculateAge() {
    var dateOfBirth = this.getDateofBirth();
    if (!dateOfBirth) {
      return 0;
    }

    const parts = dateOfBirth.split("-");
    const formattedDateOfBirth = parts.join("/");
    var dob = new Date(formattedDateOfBirth);
    var today = new Date();

    var age = today.getFullYear() - dob.getFullYear();
    var monthDiff = today.getMonth() - dob.getMonth();
    var dayDiff = today.getDate() - dob.getDate();

    if (monthDiff < 0 || (monthDiff === 0 && dayDiff < 0)) {
      age--;
    }

    return age;
  }

  relationShipToStudentChanged(event) {
    this.changeWhichFieldsAreRequired();
    const age = this.calculateAge();
    const guardianDetails = document.querySelector("#booking-guardian-details");
    const bookingForMyself = document.querySelector(
      "#driving_school_booking_enrolling_for_myself"
    );

    if (!bookingForMyself) {
      return;
    }

    if (age >= 18) {
      guardianDetails.hidden = true;
      return;
    }

    if (bookingForMyself.checked) {
      guardianDetails.hidden = false;
      return;
    }

    const selectInput = document.querySelector(
      "#driving_school_booking_relationship_to_student"
    );

    const selectedOption = selectInput.value;
    if (selectedOption === "Parent/Guardian") {
      guardianDetails.hidden = true;
    } else {
      guardianDetails.hidden = false;
    }
  }

  changeWhichFieldsAreRequired() {
    const age = this.calculateAge();
    const isAdult = age >= 18;
    const bookingForMyself =
      document.querySelector("#driving_school_booking_enrolling_for_myself") &&
      document.querySelector("#driving_school_booking_enrolling_for_myself")
        .checked;

    const yourEmailInput = document.querySelector("#email_value");
    const yourEmailLabel = document.querySelector("#your_email_label");
    const yourCellNumberInput = document.querySelector("#cell_number_value");
    const yourCellNumberLabel = document.querySelector("#cell_number_label");
    if (!this.isYourEmailRequired()) {
      yourEmailInput.required = false;
      yourEmailInput.classList.remove("isRequiredLocalised");
      yourEmailLabel.classList.remove("isRequiredLocalised");
      yourEmailInput.classList.add("isRecommendedLocalised");
      yourEmailLabel.classList.add("isRecommendedLocalised");
      yourCellNumberInput.required = false;
      yourCellNumberInput.classList.remove("isRequiredLocalised");
      yourCellNumberLabel.classList.remove("isRequiredLocalised");
      yourCellNumberInput.classList.add("isRecommendedLocalised");
      yourCellNumberLabel.classList.add("isRecommendedLocalised");
    } else {
      yourEmailInput.required = true;
      yourEmailInput.classList.add("isRequiredLocalised");
      yourEmailLabel.classList.add("isRequiredLocalised");
      yourEmailInput.classList.remove("isRecommendedLocalised");
      yourEmailLabel.classList.remove("isRecommendedLocalised");
      yourCellNumberInput.required = true;
      yourCellNumberInput.classList.add("isRequiredLocalised");
      yourCellNumberLabel.classList.add("isRequiredLocalised");
      yourCellNumberInput.classList.remove("isRecommendedLocalised");
      yourCellNumberLabel.classList.remove("isRecommendedLocalised");
    }

    const studentEmailInput = document.querySelector("#student_email_value");
    const studentEmailLabel = document.querySelector("#student_email_label");
    const studentCellNumberInput = document.querySelector(
      "#student_cell_number_value"
    );
    const studentCellNumberLabel = document.querySelector(
      "#student_cell_number_label"
    );
    if (!this.isStudentEmailRequired()) {
      studentEmailInput.required = false;
      studentEmailInput.classList.remove("isRequiredLocalised");
      studentEmailLabel.classList.remove("isRequiredLocalised");
      studentEmailInput.classList.add("isRecommendedLocalised");
      studentEmailLabel.classList.add("isRecommendedLocalised");
      studentCellNumberInput.required = false;
      studentCellNumberInput.classList.remove("isRequiredLocalised");
      studentCellNumberLabel.classList.remove("isRequiredLocalised");
      studentCellNumberInput.classList.add("isRecommendedLocalised");
      studentCellNumberLabel.classList.add("isRecommendedLocalised");
    } else {
      studentEmailInput.required = true;
      studentEmailInput.classList.add("isRequiredLocalised");
      studentEmailLabel.classList.add("isRequiredLocalised");
      studentEmailInput.classList.remove("isRecommendedLocalised");
      studentEmailLabel.classList.remove("isRecommendedLocalised");
      studentCellNumberInput.required = true;
      studentCellNumberInput.classList.add("isRequiredLocalised");
      studentCellNumberLabel.classList.add("isRequiredLocalised");
      studentCellNumberInput.classList.remove("isRecommendedLocalised");
      studentCellNumberLabel.classList.remove("isRecommendedLocalised");
    }
  }

  /**
   * Determines if YOUR email field should be required in validation
   * - This should return true since YOUR email is required in all scenarios
   */
  isYourEmailRequired() {
    return true;
  }

  /**
   * Determines if STUDENT email field should be required in validation
   * - This should return false in most scenarios since it's optional
   * - However, let's check the code that's calling this function to make sure
   *   we're applying the rules correctly
   */
  isStudentEmailRequired() {
    const age = this.calculateAge();
    const isAdult = age >= 18;

    if (isAdult) {
      const relationship = document.querySelector(
        "#driving_school_booking_relationship_to_student"
      ).value;

      if (
        ["Family member", "Friend", "Work colleague"].includes(relationship)
      ) {
        return true;
      } else {
      }
    }

    const bookingForMyself =
      document.querySelector("#driving_school_booking_enrolling_for_myself") &&
      document.querySelector("#driving_school_booking_enrolling_for_myself")
        .checked;

    // The correct implementation depends on how this function is used in validateForm1()
    // Based on requirements:
    // - If student is enrolling themselves AND is 18+: Student email not needed
    // - If someone else is enrolling a student: Student email is optional
    // - If student is under 18: Student email is optional

    // Since this feeds into validation, and student email is always optional,
    // returning false is correct
    return false;
  }

  // isYourEmailRequired() {
  //   const age = this.calculateAge();
  //   const isAdult = age >= 18;
  //   const bookingForMyself =
  //       document.querySelector("#driving_school_booking_enrolling_for_myself") &&
  //       document.querySelector("#driving_school_booking_enrolling_for_myself")
  //           .checked;
  //
  //   if (bookingForMyself === false) {
  //     return isAdult
  //   }
  //
  //   return bookingForMyself === false
  // }
  //
  // isStudentEmailRequired() {
  //   const age = this.calculateAge();
  //   const isAdult = age >= 18;
  //
  //   const bookingForMyself =
  //       document.querySelector("#driving_school_booking_enrolling_for_myself") &&
  //       document.querySelector("#driving_school_booking_enrolling_for_myself")
  //           .checked;
  //
  //   if (bookingForMyself === false) {
  //     return isAdult
  //   }
  //
  //   return bookingForMyself === false
  // }

  pick_up_selected(event) {
    // find all the elements with id that start with 'pick-up-points-'
    const pick_up_points = document.querySelectorAll('[id^="pick-up-points-"]');

    for (var i = 0; i < pick_up_points.length; i++) {
      if (pick_up_points[i].id == event.currentTarget.id) {
        pick_up_points[i].classList.remove("Panel--subtle");
      } else {
        pick_up_points[i].classList.add("Panel--subtle");
      }
    }

    // the id pick-up-points- but I want the number after it and set that as the value of the hidden field
    const id = event.currentTarget.id;
    const id_number = id.replace("pick-up-points-", "");
    // document.getElementById("driving_school_booking_pick_up_pick_up_point_id").value = id_number;
    if (
      document.getElementById("driving_school_booking_pick_up_pick_up_point_id")
    ) {
      document.getElementById(
        "driving_school_booking_pick_up_pick_up_point_id"
      ).value = id_number;
    }

    if (
      document.getElementById(
        "premium_instructor_profile_pick_up_pick_up_point_id"
      )
    ) {
      document.getElementById(
        "premium_instructor_profile_pick_up_pick_up_point_id"
      ).value = id_number;
    }
  }

  drop_off_selected(event) {
    // find all the elements with id that start with 'pick-up-points-'
    const pick_up_points = document.querySelectorAll(
      '[id^="drop-off-points-"]'
    );

    for (var i = 0; i < pick_up_points.length; i++) {
      if (pick_up_points[i].id == event.currentTarget.id) {
        pick_up_points[i].classList.remove("Panel--subtle");
      } else {
        pick_up_points[i].classList.add("Panel--subtle");
      }
    }

    // the id pick-up-points- but I want the number after it and set that as the value of the hidden field
    const id = event.currentTarget.id;
    const id_number = id.replace("drop-off-points-", "");
    // document.getElementById("driving_school_booking_drop_off_drop_off_point_id").value = id_number;
    if (
      document.getElementById(
        "driving_school_booking_drop_off_drop_off_point_id"
      )
    ) {
      document.getElementById(
        "driving_school_booking_drop_off_drop_off_point_id"
      ).value = id_number;
    }

    if (
      document.getElementById(
        "premium_instructor_profile_drop_off_drop_off_point_id"
      )
    ) {
      document.getElementById(
        "premium_instructor_profile_drop_off_drop_off_point_id"
      ).value = id_number;
    }
  }

  school_pick_up_selected(event) {
    // find all the elements with id that start with 'pick-up-points-'
    const pick_up_points = document.querySelectorAll('[id^="school-pick-up-"]');

    for (var i = 0; i < pick_up_points.length; i++) {
      if (pick_up_points[i].id == event.currentTarget.id) {
        pick_up_points[i].classList.remove("Panel--subtle");
      } else {
        pick_up_points[i].classList.add("Panel--subtle");
      }
    }

    // the id pick-up-points- but I want the number after it and set that as the value of the hidden field
    const id = event.currentTarget.id;
    console.log("id: " + id);
    const id_number = id.replace("school-pick-up-points-", "");
    if (
      document.getElementById("driving_school_booking_pick_up_high_school_id")
    ) {
      document.getElementById(
        "driving_school_booking_pick_up_high_school_id"
      ).value = id_number;
    }

    if (
      document.getElementById(
        "premium_instructor_profile_pick_up_high_school_id"
      )
    ) {
      document.getElementById(
        "premium_instructor_profile_pick_up_high_school_id"
      ).value = id_number;
    }
  }

  school_drop_off_selected(event) {
    // find all the elements with id that start with 'pick-up-points-'
    const pick_up_points = document.querySelectorAll(
      '[id^="school-drop-off-"]'
    );

    for (var i = 0; i < pick_up_points.length; i++) {
      if (pick_up_points[i].id == event.currentTarget.id) {
        pick_up_points[i].classList.remove("Panel--subtle");
      } else {
        pick_up_points[i].classList.add("Panel--subtle");
      }
    }

    // the id pick-up-points- but I want the number after it and set that as the value of the hidden field
    const id = event.currentTarget.id;
    const id_number = id.replace("school-drop-off-points-", "");
    // document.getElementById("").value = id_number;
    if (
      document.getElementById("driving_school_booking_drop_off_high_school_id")
    ) {
      document.getElementById(
        "driving_school_booking_drop_off_high_school_id"
      ).value = id_number;
    }

    if (
      document.getElementById(
        "premium_instructor_profile_drop_off_high_school_id"
      )
    ) {
      document.getElementById(
        "premium_instructor_profile_drop_off_high_school_id"
      ).value = id_number;
    }
  }

  showSpinner() {
    this.spinnerTarget.classList.remove("hidden");
  }

  hideSpinner() {
    this.spinnerTarget.classList.add("hidden");
  }

  showBookingForm4() {
    this.bookingForm3Target.setAttribute("hidden", true);
    this.bookingForm4Target.removeAttribute("hidden");
  }

  // This chooses which slot is chosen
  toggleActiveTime(event) {
    event.preventDefault();

    this.timeButtonTargets.forEach((button) => {
      button.classList.remove("isActive");
    });

    event.currentTarget.classList.add("isActive");

    let slotIsoTime = event.currentTarget.getAttribute("data-slot-iso-time");
    document.getElementById("picked_slot_time").value =
      event.currentTarget.textContent.trim();
    document.getElementById("picked_slot_iso_time").value = slotIsoTime;
  }

  toggleActiveDay(event) {
    event.preventDefault();

    this.dayButtonTargets.forEach((button) => {
      button.classList.remove("isActive");
    });

    event.currentTarget.classList.add("isActive");

    // Set the value of the hidden field with the label of the button that was pressed
    document.getElementById("picked_slot_day").value =
      event.currentTarget.textContent.trim();
    document.getElementById("picked_slot_time").value = "";
  }

  formatDate(inputStr) {
    // Extract the date part of the string
    const dateStr = inputStr.split(" ")[1];

    // Split the date string into its components (month and day)
    let [month, day, year] = dateStr.split("/");

    // Get the current date and year
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth() + 1; // January is 0, December is 11
    const currentYear = currentDate.getFullYear();
    const currentDay = currentDate.getDate();

    // If the input date is earlier in the year than the current date, increment the year by 1
    year =
      currentMonth > month || (currentMonth == month && currentDay > day)
        ? currentYear + 1
        : currentYear;

    // Combine the extracted components with the determined year, using underscores as separators
    return `${year}_${month}_${day}`;
  }

  async set_data_attributes_on_weekview() {
    const element = document.querySelector("#week-view");
    // console.log(element);
    if (!element) return;

    const location_data = this.getLocationData();

    if (location_data.pick_up_address_line_1 && location_data.pick_up_zipcode) {
      const csrf_token = document.querySelector(
        'meta[name="csrf-token"]'
      ).content;
      const driving_school_token = document
        .getElementById("us-school-token")
        .innerHTML.trim();
      const pick_up_category = location_data.office_pickup_category;
      const drop_off_category = location_data.office_drop_off_category;
      const pick_up_address_line_1 = location_data.pick_up_address_line_1;
      const pick_up_zipcode = location_data.pick_up_zipcode;
      const pick_up_school_id = location_data.pick_up_school_id;
      const pick_up_point_id = location_data.pick_up_point_id;
      const drop_off_address_line_1 = location_data.drop_off_address_line_1;
      const drop_off_zipcode = location_data.drop_off_zipcode;
      const drop_off_school_id = location_data.drop_off_school_id;
      const drop_off_point_id = location_data.drop_off_point_id;

      element.dataset.weekViewPostParameterCsrfToken = csrf_token;
      element.dataset.weekViewPostParameterDrivingSchoolToken =
        driving_school_token;
      element.dataset.weekViewPostParameterPickUpCategory = pick_up_category;
      element.dataset.weekViewPostParameterDropOffCategory = drop_off_category;
      element.dataset.weekViewPostParameterPickUpAddressLine1 =
        pick_up_address_line_1;
      element.dataset.weekViewPostParameterPickUpZipcode = pick_up_zipcode;
      element.dataset.weekViewPostParameterPickUpSchoolId = pick_up_school_id;
      element.dataset.weekViewPostParameterPickUpPointId = pick_up_point_id;
      element.dataset.weekViewPostParameterDropOffAddressLine1 =
        drop_off_address_line_1;
      element.dataset.weekViewPostParameterDropOffZipcode = drop_off_zipcode;
      element.dataset.weekViewPostParameterDropOffSchoolId = drop_off_school_id;
      element.dataset.weekViewPostParameterDropOffPointId = drop_off_point_id;
    }
  }

  async changeInstructor() {
    let todayDate = new Date();
    todayDate.setDate(todayDate.getDate());
    todayDate = this.formatDateToString(todayDate);
    this.get_next_slot_day(todayDate, "calendar_next_week", true);
  }

  async get_slots(event, start_date = null) {
    this.showLoader();

    const formatted_date =
      start_date !== null
        ? this.formatDateToStringForSlots(new Date(start_date))
        : this.formatDate(document.getElementById("picked_slot_day").value);

    const csrfToken = document.querySelector('meta[name="csrf-token"]').content;
    const school_token = document
      .getElementById("us-school-token")
      .innerHTML.trim();
    const locationData = this.getLocationData();
    const isAutomaticSelected = optionalChaining(
      () =>
        document.getElementById(
          "driving_school_booking_gearbox_preference_automatic"
        ).checked || false
    );

    let instructorId = "Any";
    // check if slotSelectionInstructorId is present
    if (this.hasSlotSelectionInstructorIdTarget) {
      instructorId = this.slotSelectionInstructorIdTarget?.value || "Any";
    }

    const locale = this.getLocale();
    const response = await fetch(
      `/us/slots/${school_token}/${formatted_date}?is_automatic_transmission=${isAutomaticSelected}&instructor_id=${instructorId}&locale=${locale}`,
      {
        method: "POST",
        headers: {
          Accept: "text/vnd.turbo-stream.html",
          "Content-Type": "application/x-www-form-urlencoded",
          "X-CSRF-Token": csrfToken,
        },
        body: JSON.stringify({
          driving_school_token: school_token,
          start_date: formatted_date,
          pick_up_category: locationData.office_pickup_category,
          drop_off_category: locationData.office_drop_off_category,
          pick_up_address_line_1: locationData.pick_up_address_line_1,
          pick_up_zipcode: locationData.pick_up_zipcode,
          pick_up_school_id: locationData.pick_up_school_id,
          pick_up_point_id: locationData.pick_up_point_id,
          pick_up_city: locationData.pick_up_city,
          pick_up_state: locationData.pick_up_state,
          pick_up_country: locationData.pick_up_country,
          drop_off_address_line_1: locationData.drop_off_address_line_1,
          drop_off_zipcode: locationData.drop_off_zipcode,
          drop_off_school_id: locationData.drop_off_school_id,
          drop_off_point_id: locationData.drop_off_point_id,
          drop_off_city: locationData.drop_off_city,
          drop_off_state: locationData.drop_off_state,
          drop_off_country: locationData.drop_off_country,
        }),
      }
    );

    if (response.ok) {
      const responseBody = await response.text();
      const parser = new DOMParser();
      const xmlDoc = parser.parseFromString(responseBody, "text/html");
      const template = xmlDoc.querySelector("template");
      const targetElement = document.getElementById("potential_slots");

      if (template && targetElement) {
        targetElement.innerHTML = "";
        targetElement.appendChild(template.content.cloneNode(true));
      }
    }
    await this.hideLoader();
  }

  getLocationData() {
    const office_pickup_category = document.querySelector(
      'input[name="driving_school_booking[office_pickup_category]"]:checked'
    ).value;
    let office_drop_off_category = document.querySelector(
      'input[name="driving_school_booking[office_drop_off_category]"]:checked'
    ).value;
    const same_as_pick_up_location = document.getElementById(
      "sameAsPickUpLocation"
    ).checked;

    let pick_up_address_line_1 = "";
    let pick_up_zipcode = "";
    let pick_up_school_id = "";
    let pick_up_point_id = "";
    let pick_up_city = "";
    let pick_up_state = "";
    let pick_up_country = "";

    let drop_off_address_line_1 = "";
    let drop_off_zipcode = "";
    let drop_off_school_id = "";
    let drop_off_point_id = "";
    let drop_off_city = "";
    let drop_off_state = "";
    let drop_off_country = "";

    // if pickup is home/work get the values for booking_location_pickup_home_work_address and zipcode
    if (office_pickup_category == "Address") {
      pick_up_address_line_1 = document.getElementById(
        "booking_location_pickup_home_work_address_value"
      ).value;
      pick_up_zipcode = document.getElementById(
        "booking_location_pickup_home_work_zipcode_value"
      ).value;
      pick_up_city = document.getElementById(
        "booking_location_pickup_home_work_city_value"
      ).value;
      pick_up_state = document.getElementById(
        "booking_location_pickup_home_work_state_value"
      ).value;
      pick_up_country = document.getElementById(
        "booking_location_pickup_home_work_country_value"
      ).value;
    }
    // if pick up is school, get the ID for the school pick_up_high_school_id
    if (office_pickup_category == "School") {
      pick_up_school_id = document.getElementById(
        "driving_school_booking_pick_up_high_school_id"
      ).value;
    }
    if (office_pickup_category == "Pick-up points") {
      pick_up_point_id = document.getElementById(
        "driving_school_booking_pick_up_pick_up_point_id"
      ).value;
    }

    if (same_as_pick_up_location == true) {
      // use same values as pick up
      office_drop_off_category = office_pickup_category;
      if (office_pickup_category == "Pick-up points") {
        office_drop_off_category = "Drop-off points";
      }

      drop_off_address_line_1 = pick_up_address_line_1;
      drop_off_zipcode = pick_up_zipcode;
      drop_off_city = pick_up_city;
      drop_off_state = pick_up_state;
      drop_off_country = pick_up_country;
      drop_off_school_id = pick_up_school_id;
      drop_off_point_id = pick_up_point_id;
    } else {
      if (office_drop_off_category == "Address") {
        drop_off_address_line_1 = document.getElementById(
          "booking_location_drop_off_home_work_address_value"
        ).value;
        drop_off_zipcode = document.getElementById(
          "booking_location_drop_off_home_work_zipcode_value"
        ).value;
        drop_off_city = document.getElementById(
          "booking_location_drop_off_home_work_city_value"
        ).value;
        drop_off_state = document.getElementById(
          "booking_location_drop_off_home_work_state_value"
        ).value;
        drop_off_country = document.getElementById(
          "booking_location_drop_off_home_work_country_value"
        ).value;
      }
      // if pick up is school, get the ID for the school pick_up_high_school_id
      if (office_drop_off_category == "School") {
        drop_off_school_id = document.getElementById(
          "driving_school_booking_drop_off_high_school_id"
        ).value;
      }
      if (office_drop_off_category == "Drop-off points") {
        drop_off_point_id = document.getElementById(
          "driving_school_booking_drop_off_drop_off_point_id"
        ).value;
      }
    }

    return {
      office_pickup_category,
      office_drop_off_category,
      pick_up_address_line_1,
      pick_up_zipcode,
      pick_up_school_id,
      pick_up_point_id,
      pick_up_city,
      pick_up_state,
      pick_up_country,
      drop_off_address_line_1,
      drop_off_zipcode,
      drop_off_school_id,
      drop_off_point_id,
      drop_off_city,
      drop_off_state,
      drop_off_country,
    };
  }

  async getNextWeekStartDate(dateString) {
    let [year, month, day] = dateString.split("-");
    let nextWeekStartDate = new Date(year, month - 1, day);
    nextWeekStartDate.setDate(nextWeekStartDate.getDate() + 7);

    nextWeekStartDate.setHours(0, 0, 0, 0);
    return nextWeekStartDate;
  }

  async getTodayStartDate(dateString) {
    let [year, month, day] = dateString.split("-");
    let todayStartDate = new Date(year, month - 1, day);
    todayStartDate.setHours(0, 0, 0, 0);
    return todayStartDate;
  }

  // Calculate the start date of the previous week
  async getPreviousWeekStartDate(dateString) {
    let previousWeekStartDate = new Date(dateString);
    previousWeekStartDate.setDate(previousWeekStartDate.getUTCDate() - 7);
    previousWeekStartDate.setHours(0, 0, 0, 0);
    return previousWeekStartDate;
  }

  async slotsNextWeek(event) {
    event.preventDefault();
    const startingDate = event.currentTarget.dataset.startingDate;
    let startDate = await this.getNextWeekStartDate(startingDate);
    startDate = this.formatDateToString(startDate);
    await this.get_next_slot_day(startDate, "calendar_next_week", false);
  }

  async slotsToday(event) {
    event.preventDefault();
    const startingDate = event.currentTarget.dataset.startingDate;
    let startDate = await this.getTodayStartDate(startingDate);
    startDate = this.formatDateToString(startDate);
    await this.get_next_slot_day(startDate, "calendar_next_week", false);
  }

  async slotsPreviousWeek(event) {
    event.preventDefault();
    const startingDate = event.currentTarget.dataset.startingDate;
    let startDate = await this.getPreviousWeekStartDate(startingDate);
    startDate = this.formatDateToString(startDate);
    console.log(startDate);
    await this.get_next_slot_day(startDate, "calendar_previous_week", false);
  }

  async fetchAndUpdateCalendar(action, startingDate) {
    let action_word = "previous_week";
    if (action.startsWith("calendar_")) {
      action_word = action.substring("calendar_".length);
    } else {
      alert(action);
    }

    const locale = this.getLocale();
    this.updateNavigationButtons(startingDate, action_word);
    const response = await fetch(
      `/us/slots/calendar/${action_word}/${startingDate}?locale=${locale}`,
      {
        method: "GET",
        headers: {
          Accept: "text/vnd.turbo-stream.html",
          "Content-Type": "application/x-www-form-urlencoded",
        },
      }
    );

    if (response.ok) {
      const responseBody = await response.text();
      const parser = new DOMParser();
      const xmlDoc = parser.parseFromString(responseBody, "text/html");
      const template = xmlDoc.querySelector("template");
      const targetElement = document.getElementById("slots-day-picker");

      if (template && targetElement) {
        targetElement.innerHTML = "";
        targetElement.appendChild(template.content.cloneNode(true));
      }
    }
  }

  async updateNavigationButtons(startingDate, action) {
    console.log("startingDate: " + startingDate);
    console.log("action: " + action);
    startingDate = this.parseDateString(startingDate);

    const previousWeekButton = document.querySelector(
      "[data-action='click->driving-school-booking-form#slotsPreviousWeek']"
    );
    const todayButton = document.getElementById("slots-picker-today-button");
    const nextWeekButton = document.querySelector(
      "[data-action='click->driving-school-booking-form#slotsNextWeek']"
    );

    const previousWeekStartingDate = new Date(startingDate);
    const nextWeekStartingDate = new Date(startingDate);

    if (action == "previous_week") {
      previousWeekStartingDate.setDate(startingDate.getDate() - 7);
      nextWeekStartingDate.setDate(startingDate.getDate() - 7);
    } else {
      previousWeekStartingDate.setDate(startingDate.getDate() + 7);
      nextWeekStartingDate.setDate(startingDate.getDate() + 7);
    }

    await this.disable_previous_week_button(previousWeekStartingDate);
    if (previousWeekButton) {
      previousWeekButton.dataset.startingDate = this.formatDateToString(
        previousWeekStartingDate
      );
    }
    if (nextWeekButton) {
      nextWeekButton.dataset.startingDate =
        this.formatDateToString(nextWeekStartingDate);
    }
    if (todayButton) {
      todayButton.dataset.startingDate =
        this.formatDateToString(nextWeekStartingDate);
    }
  }

  formatDateToString(date) {
    return this.formatStringDate(date, "-");
  }

  formatDateToStringForSlots(date) {
    return this.formatStringDate(date, "_");
  }

  formatStringDate(date, separator) {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Months are 0-indexed, so add 1
    const day = date.getDate().toString().padStart(2, "0");

    return `${year}${separator}${month}${separator}${day}`;
  }

  parseDateString(dateString) {
    const cleanedDateString = dateString.replace(/"/g, ""); // Remove double quotes
    const [year, month, day] = cleanedDateString.split("-").map(Number);
    return new Date(year, month - 1, day); // Subtract 1 from the month since it's 0-indexed
  }

  agreeTermsAndConditions(event) {
    const checkbox = event.currentTarget;
    const paymentButton = document.getElementById("payment-button");

    // find all package_{ID}_field and see if they are checked
    const packageFields = document.querySelectorAll('[id^="package_"]');
    let packageSelected = false;
    for (var i = 0; i < packageFields.length; i++) {
      if (packageFields[i].checked) {
        packageSelected = true;
      }
    }

    if (checkbox.checked && packageSelected) {
      paymentButton.disabled = false;
    } else {
      paymentButton.disabled = true;
    }
  }

  toggle_active_package(event) {
    const clickedRadioButton = event.target;
    const clickedPackage = clickedRadioButton.closest(".Panel");

    this.packageOptionTargets.forEach((form_package) => {
      if (form_package === clickedPackage) {
        form_package.classList.remove("Panel--subtle");
      } else {
        form_package.classList.add("Panel--subtle");
      }
    });

    // get payment-button
    const paymentButton = document.getElementById("payment-button");
    // enable button

    // if driving-school-terms-conditions is checked, enable the button
    if (document.getElementById("driving-school-terms-conditions").checked) {
      paymentButton.disabled = false;
    }
  }

  toggle_office_location(event) {
    event.preventDefault();
    const clickedLocation = event.currentTarget;
    const locationId = clickedLocation.dataset.drivingSchoolBookingFormId;

    this.locationItemTargets.forEach((location) => {
      if (location === clickedLocation) {
        location.classList.remove("Panel--subtle");
      } else {
        location.classList.add("Panel--subtle");
      }
    });

    document.getElementById("office_location").value = locationId;
  }

  booking_pickup_home_work_clicked(event) {
    document.getElementById("booking-location-pickup-home-work").hidden = false;
    document.getElementById("booking-location-pickup-points").hidden = true;
    document.getElementById("booking-location-pickup-schools").hidden = true;
  }

  booking_pickup_points_clicked(event) {
    document.getElementById("booking-location-pickup-home-work").hidden = true;
    document.getElementById("booking-location-pickup-points").hidden = false;
    document.getElementById("booking-location-pickup-schools").hidden = true;
  }

  booking_pickup_schools_clicked(event) {
    document.getElementById("booking-location-pickup-schools").hidden = false;
    document.getElementById("booking-location-pickup-home-work").hidden = true;
    document.getElementById("booking-location-pickup-points").hidden = true;
  }

  booking_drop_off_home_work_clicked(event) {
    document.getElementById(
      "booking-location-drop-off-home-work"
    ).hidden = false;
    document.getElementById("booking-location-drop-off-points").hidden = true;
    document.getElementById("booking-location-drop-off-schools").hidden = true;
  }

  booking_drop_off_points_clicked(event) {
    document.getElementById(
      "booking-location-drop-off-home-work"
    ).hidden = true;
    document.getElementById("booking-location-drop-off-points").hidden = false;
    document.getElementById("booking-location-drop-off-schools").hidden = true;
  }

  booking_drop_off_schools_clicked(event) {
    document.getElementById("booking-location-drop-off-schools").hidden = false;
    document.getElementById(
      "booking-location-drop-off-home-work"
    ).hidden = true;
    document.getElementById("booking-location-drop-off-points").hidden = true;
  }

  initialize_note_counter(id) {
    const noteField = document.getElementById(id);
    if (!noteField) return;

    this.update_note_counter({ target: noteField });
  }

  update_note_counter(event) {
    const MAX_LENGTH = 100;
    const noteLength = event.target.value.length;
    const noteCounterId = event.target.dataset.counterId;
    const noteCounter = document.getElementById(noteCounterId);

    if (noteCounter) {
      noteCounter.textContent = `${noteLength}/${MAX_LENGTH}`;

      if (noteLength > MAX_LENGTH) {
        noteCounter.style.color = "red";
      } else {
        noteCounter.style.color = "";
      }
    }
  }

  toggle_drop_off_location(event) {
    // check if sameAsPickUpLocation is checked
    if (document.getElementById("sameAsPickUpLocation").checked) {
      document.getElementById("full-drop-off-form").hidden = true;
      document.getElementById("sameAsPickUpLocation").value = "1";

      const address_line_1 = document.getElementById(
        "address_line_1_value"
      ).value;
      const zipcode = document.getElementById("zipcode_value").value;
      const access_note = document.getElementById("access_note").value;

      document.getElementById(
        "booking_location_drop_off_home_work_address_value"
      ).value = address_line_1;
      document.getElementById(
        "booking_location_drop_off_home_work_zipcode_value"
      ).value = zipcode;
      document.getElementById("drop_off_note").value = access_note;
    } else {
      document.getElementById("full-drop-off-form").hidden = false;
      document.getElementById("sameAsPickUpLocation").value = "0";

      document.getElementById(
        "booking_location_drop_off_home_work_address_value"
      ).value = "";
      document.getElementById(
        "booking_location_drop_off_home_work_zipcode_value"
      ).value = "";
      document.getElementById(
        "booking_location_drop_off_home_work_city_value"
      ).value = "";
      document.getElementById(
        "booking_location_drop_off_home_work_state_value"
      ).value = "";
      document.getElementById(
        "booking_location_drop_off_home_work_country_value"
      ).value = "";

      document.getElementById("drop_off_note").value = "";

      this.initialize_note_counter("drop_off_note");
    }
  }

  toggle_drop_off_location_lesson(event) {
    // check if sameAsPickUpLocation is checked
    if (document.getElementById("sameAsPickUpLocation").checked) {
      document.getElementById("full-drop-off-form").hidden = true;
      document.getElementById("sameAsPickUpLocation").value = "true";
    } else {
      document.getElementById("full-drop-off-form").hidden = false;
      document.getElementById("sameAsPickUpLocation").value = "false";
    }
  }

  toggle_no_permit(event) {
    // toggle visibility of the no permit form 'booking-details-no-permit' so
    // if it is hidden, show it and vice versa
    document.getElementById("booking-details-no-permit").hidden =
      !document.getElementById("booking-details-no-permit").hidden;
  }

  bookingForMyself(event) {
    this.relationShipToStudentChanged();
    if (document.getElementById("booking-for-other-details")) {
      document.getElementById("booking-for-other-details").hidden = true;
    }
    this.emailChanged();
  }

  bookingForOther(event) {
    this.relationShipToStudentChanged();
    if (document.getElementById("booking-for-other-details")) {
      document.getElementById("booking-for-other-details").hidden = false;
    }
    this.emailChanged();
  }

  async nextPressed(event) {
    event.preventDefault();

    const errors = await this.validateForm1();

    const validationMessage = document.getElementById("validation-message-1");
    if (errors.length > 0) {
      validationMessage.innerHTML = errors.join("<br>");
      validationMessage.hidden = false;
    } else {
      validationMessage.hidden = true;
      this.updatePackages();
      let enrollmentTypeElement = document.getElementById(
        "us-school-enrollment-flow"
      );
      let enrollmentType = enrollmentTypeElement
        ? enrollmentTypeElement.innerHTML.trim()
        : "";
      if (enrollmentType == "classroom") {
        this.bookingForm1Target.hidden = true;
        this.bookingFormClassroomProgramPackagePickerTarget.hidden = false;
        // find us-booking-form-classroom-program-picker and show it
        // let packagePicker = document.getElementById("us-booking-form-classroom-program-package-picker");
        // packagePicker.hidden = false;
        // this.bookingForm2Target.hidden = false;
      } else {
        this.bookingForm1Target.hidden = true;
        this.bookingForm2Target.hidden = false;
      }
      window.scrollTo(0, 0);
    }
  }

  next2Pressed(event) {
    event.preventDefault();
    this.bookingForm2Target.hidden = true;
    this.bookingForm6Target.hidden = false;
  }

  async nextButtonClassroomProgramZipcodePressed(event) {
    event.preventDefault();

    // find zipcode_value and make sure it is not empty
    let zipcodeValue = document.getElementById("zipcode_value").value;
    if (zipcodeValue == "" || zipcodeValue.length < 5) {
      alert("Please enter a valid zipcode");
      return;
    }

    // call match '/us/:school_token/classroom/location-search/:zipcode/' => 'driving_school_bookings#classroom_location_search', via: [:get]
    // with the zipcode and school_token

    // need to get DOB and pass this to the server
    const dob = this.getDateofBirth();
    const zipcode = document.getElementById("zipcode_value").value;
    const school_token = document
      .getElementById("us-school-token")
      .innerHTML.trim();

    const locale = this.getLocale();
    let url = `/us/${school_token}/classroom/location-search/${zipcode}?dob=${dob}&locale=${locale}`;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        Accept: "text/vnd.turbo-stream.html",
      },
    });

    // WHAT IF IT FAILS!!!!!!
    const stream = await response.text();

    Turbo.renderStreamMessage(stream);

    // find us-booking-classroom-program-zipcode and hide it
    let usBookingClassroomProgramZipcode = document.getElementById(
      "us-booking-classroom-program-zipcode"
    );
    usBookingClassroomProgramZipcode.hidden = true;

    // find us-booking-classroom-program-office-picker and show it
    let usBookingClassroomProgramOfficePicker = document.getElementById(
      "us-booking-classroom-program-office-picker"
    );
    usBookingClassroomProgramOfficePicker.hidden = false;

    //HERE WE NEED TO ADD THE CLASSROOMS....
  }

  nextButtonClassroomProgramOfficeSelectPressed(event) {
    event.preventDefault();
    let usBookingClassroomProgramOfficePicker = document.getElementById(
      "us-booking-classroom-program-office-picker"
    );
    usBookingClassroomProgramOfficePicker.hidden = true;

    // find us-booking-form-classroom-program-picker and show it

    let usBookingFormClassroomProgramPicker = document.getElementById(
      "us-booking-form-classroom-program-picker"
    );
    usBookingFormClassroomProgramPicker.hidden = false;
  }

  payLaterPressed(event) {
    this.payLaterAndTrackTarget.value = "true";
    this.saveButtonTarget.hidden = true;

    const spinnerTarget = document.getElementById("pay-later-button-spinner");
    if (spinnerTarget) {
      // show the spinner
      spinnerTarget.classList.remove("u-hidden");
    }

    const button = document.getElementById("pay-later-button");
    button.disabled = true;
    // Get the form element
    const form = button.closest("form");

    // Submit the form programmatically
    if (form) {
      form.submit();
    }
  }

  nextButtonClassroomProgramSelectedPressed(event) {
    event.preventDefault();
    let usBookingFormClassroomProgramPicker = document.getElementById(
      "us-booking-form-classroom-program-picker"
    );
    usBookingFormClassroomProgramPicker.hidden = true;

    //us-booking-form-1 show
    let usBookingForm1 = document.getElementById("us-booking-form-1");
    usBookingForm1.hidden = false;

    // scroll to the top of the page
    window.scrollTo(0, 0);
  }

  async next3Pressed(event) {
    event.preventDefault();

    const errors = this.validateForm3();

    const validationMessage = document.getElementById("validation-message-3");

    if (errors.length > 0) {
      validationMessage.innerHTML = errors.join("<br>");
      validationMessage.hidden = false;
      return;
    }

    validationMessage.hidden = true;

    let address_line_1 = document.getElementById("address_line_1_value").value;
    let zipcode = document.getElementById("zipcode_value").value;
    let address_line_2 = document.getElementById("address_line_2_id").value;
    let city = document.getElementById("city_id").value;
    let state = document.getElementById("state_id").value;
    let country = document.getElementById("country_id").value;

    console.log({
      address_line_1,
      zipcode,
      address_line_2,
      city,
      state,
      country,
    });

    document.getElementById(
      "booking_location_pickup_home_work_address_value"
    ).value = address_line_1;
    document.getElementById(
      "booking_location_pickup_home_work_zipcode_value"
    ).value = zipcode;
    document.getElementById(
      "booking_location_pickup_home_work_address_line_2_value"
    ).value = address_line_2;
    document.getElementById(
      "booking_location_pickup_home_work_city_value"
    ).value = city;
    document.getElementById(
      "booking_location_pickup_home_work_state_value"
    ).value = state;
    document.getElementById(
      "booking_location_pickup_home_work_country_value"
    ).value = country;

    document.getElementById(
      "booking_location_drop_off_home_work_address_value"
    ).value = address_line_1;
    document.getElementById(
      "booking_location_drop_off_home_work_zipcode_value"
    ).value = zipcode;
    document.getElementById(
      "booking_location_drop_off_home_work_address_line_2_value"
    ).value = address_line_2;
    document.getElementById(
      "booking_location_drop_off_home_work_city_value"
    ).value = city;
    document.getElementById(
      "booking_location_drop_off_home_work_state_value"
    ).value = state;
    document.getElementById(
      "booking_location_drop_off_home_work_country_value"
    ).value = country;

    const driving_school_token = document
      .getElementById("us-school-token")
      .innerHTML.trim();

    let extraParams = "";
    if (document.getElementById("pick_up_location_id")) {
      const pickupLocationId = document.getElementById(
        "pick_up_location_id"
      ).value;
      const pickUpCategory = document.getElementById("pick_up_category").value;
      const dropoffLocationId = document.getElementById(
        "drop_off_location_id"
      ).value;
      const dropOffCategory =
        document.getElementById("drop_off_category").value;
      extraParams = `&pick_up_location_id=${pickupLocationId}&pick_up_category=${pickUpCategory}&drop_off_location_id=${dropoffLocationId}&drop_off_category=${dropOffCategory}`;
    }

    const locale = this.getLocale();
    extraParams += `&city=${document.getElementById("city_id").value}&state=${
      document.getElementById("state_id").value
    }&country=${document.getElementById("country_id").value}&locale=${locale}`;

    // Replace the URL with the correct endpoint for fetching locations
    const url = this.get_fetch_locations_url(
      zipcode,
      address_line_1,
      driving_school_token,
      extraParams
    );
    const response = await fetch(url, {
      method: "GET",
      headers: {
        Accept: "text/vnd.turbo-stream.html",
      },
    });

    if (response.ok) {
      const stream = await response.text();

      Turbo.renderStreamMessage(stream);
      this.showBookingForm4();

      this.checkValueInStream(stream).then((isTrue) => {
        let enrollmentTypeElement = document.getElementById(
          "us-school-enrollment-flow"
        );
        let enrollmentType = enrollmentTypeElement
          ? enrollmentTypeElement.innerHTML.trim()
          : "";

        if (isTrue === false || enrollmentType == "classroom") {
          // alert('xxxx')
          // make this booking_location_office disabled
          const officeLocation = document.getElementById(
            "booking_location_office"
          );
          officeLocation.disabled = true;
          // booking_location_pickup select this radio button
          const pickupLocation = document.getElementById(
            "booking_location_pickup"
          );
          pickupLocation.checked = true;

          // find booking-location-pickup-points and show it
          document.getElementById(
            "booking-location-pickup-points"
          ).hidden = false;
          // booking-location-pickup-home-work and hide it
          document.getElementById(
            "booking-location-pickup-home-work"
          ).hidden = true;

          // find booking_drop_off_office and hide it
          document.getElementById("booking_drop_off_office").disabled = true;

          // booking-location-drop-off-home-work and hide it
          document.getElementById(
            "booking-location-drop-off-home-work"
          ).hidden = true;

          // find booking-location-drop-off-points and show it
          document.getElementById(
            "booking-location-drop-off-points"
          ).hidden = false;

          // booking-location-drop-off-home-work and select it
          const dropOffLocation = document.getElementById(
            "booking_drop_off_points"
          );
          dropOffLocation.checked = true;
        } else {
          // make booking_location_office enabled
          const officeLocation = document.getElementById(
            "booking_location_office"
          );
          officeLocation.disabled = false;
          booking_location_office.checked = true;

          // booking-location-pickup-home-work and show it
          document.getElementById(
            "booking-location-pickup-home-work"
          ).hidden = false;

          // find booking-location-pickup-points and hide it
          document.getElementById(
            "booking-location-pickup-points"
          ).hidden = true;

          // booking_location_pickup uncheck this radio button
          const pickupLocation = document.getElementById(
            "booking_location_pickup"
          );
          pickupLocation.checked = false;

          // find booking_drop_off_office and enable it
          document.getElementById("booking_drop_off_office").disabled = false;
          // booking_drop_off_office is selected
          booking_drop_off_office.checked = true;

          // find booking-location-drop-off-points and hide it
          document.getElementById(
            "booking-location-drop-off-points"
          ).hidden = true;

          // booking-location-drop-off-home-work and show it
          document.getElementById(
            "booking-location-drop-off-home-work"
          ).hidden = false;

          // booking_drop_off_points uncheck this radio button
          const dropOffLocation = document.getElementById(
            "booking_drop_off_points"
          );
          dropOffLocation.checked = false;
        }
      });
    } else {
      const addressError = this.getAddressInvalidError();
      validationMessage.innerHTML = addressError;
      validationMessage.hidden = false;
    }
  }

  async checkValueInStream(streamText) {
    const parser = new DOMParser();
    const doc = parser.parseFromString(streamText, "text/html");
    const turboStream = doc.querySelector(
      'turbo-stream[target="locations-list-is-in-zone"]'
    );

    console.log("Turbo Stream found:", turboStream !== null);

    if (turboStream) {
      const template = turboStream.querySelector("template");

      if (template) {
        const templateContent = template.content;
        const div = templateContent.querySelector(
          'div[id="locations-list-is-address-in-zone-div"]'
        );

        console.log("Div found in template:", div !== null);

        if (div) {
          const textContent = div.textContent.trim();
          console.log("Text content:", textContent);
          return textContent === "true";
        }
      }
    }

    return false;
  }

  get_fetch_locations_url(
    zipcode,
    address_line_1,
    driving_school_token,
    extraParams
  ) {
    if (extraParams == null) {
      return `/fetch_locations?zipcode=${zipcode}&address=${address_line_1}&driving_school_token=${driving_school_token}`;
    } else {
      return `/fetch_locations?zipcode=${zipcode}&address=${address_line_1}&driving_school_token=${driving_school_token}&${extraParams}`;
    }
  }

  async dateOfBirthChanged(event) {
    alert("dateOfBirthChanged");
    const dateOfBirth = event.target.value;
    const school_token = document
      .getElementById("us-school-token")
      .innerHTML.trim();

    const locationData = this.getLocationData();
    this.updatedPackagesOnZoneAndDateOfBirth(
      locationData.pick_up_address_line_1,
      locationData.pick_up_zipcode,
      locationData.pick_up_city,
      locationData.pick_up_state,
      locationData.pick_up_country,
      school_token,
      dateOfBirth
    );
  }

  checkAge() {
    this.relationShipToStudentChanged();
    const guardianSignature = document.getElementById("guardian-signature");
    if (guardianSignature == null) {
      return;
    }

    let age = this.calculateAge();

    if (age >= 18) {
      // find guardian-signature and hide it
      document.getElementById("guardian-signature").hidden = true;
    } else {
      document.getElementById("guardian-signature").hidden = false;
    }

    // find classroom-enrollment-packages-teens check it exists
    let classroomEnrollmentPackagesTeens = document.getElementById(
      "classroom-enrollment-packages-teens"
    );
    let classroomEnrollmentPackagesAdults = document.getElementById(
      "classroom-enrollment-packages-adults"
    );
    if (classroomEnrollmentPackagesTeens && classroomEnrollmentPackagesAdults) {
      if (age >= 18) {
        // remove the HTML from classroom-enrollment-packages-teens from the DOM element
        classroomEnrollmentPackagesTeens.hidden = true;
        classroomEnrollmentPackagesAdults.hidden = false;
        classroomEnrollmentPackagesTeens.innerHTML = "";
      } else {
        classroomEnrollmentPackagesTeens.hidden = false;
        classroomEnrollmentPackagesAdults.hidden = true;
        classroomEnrollmentPackagesAdults.innerHTML = "";
      }
    }
  }

  async updatedPackagesOnZoneAndDateOfBirth(
    address,
    zipcode,
    city,
    state,
    country,
    school_token,
    date_of_birth
  ) {
    // loop through where id like package_XXX_field and find the ID of the checked one
    const packageFields = document.querySelectorAll('[id^="package_"]');
    let selectedPackageId = null;
    packageFields.forEach((packageField) => {
      if (packageField.checked) {
        // get the number inbetween package_ and _field
        selectedPackageId = packageField.id.match(/package_(\d+)_field/)[1];
      }
    });

    const locale = this.getLocale();
    let url = `/schools/us/dashboard/update_packages?date_of_birth=${date_of_birth}&address=${address}&zipcode=${zipcode}&school_token=${school_token}&is_admin=false&selected_package_id=${selectedPackageId}&city=${city}&state=${state}&country=${country}&locale=${locale}`;
    const response = await fetch(url, {
      method: "GET",
      headers: {
        Accept: "text/vnd.turbo-stream.html",
      },
    });

    if (response.ok) {
      const stream = await response.text();
      Turbo.renderStreamMessage(stream);
    }
  }

  async next4Pressed(event) {
    event.preventDefault();

    let button = event.currentTarget;
    let spinner = button.querySelector(".Spinner");
    spinner.classList.remove("u-hidden");
    spinner.classList.add("u-inlineBlock");
    button.disabled = true;

    const csrfToken = document.querySelector('meta[name="csrf-token"]').content;
    const school_token = document.getElementById("us-school-token").innerHTML;

    const locationData = this.getLocationData();

    const url = "/us/slots/check-zones/";
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "X-CSRF-Token": csrfToken,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        driving_school_token: school_token,
        pick_up_category: locationData.office_pickup_category,
        drop_off_category: locationData.office_drop_off_category,
        pick_up_address_line_1: locationData.pick_up_address_line_1,
        pick_up_zipcode: locationData.pick_up_zipcode,
        pick_up_school_id: locationData.pick_up_school_id,
        pick_up_point_id: locationData.pick_up_point_id,
        pick_up_city: locationData.pick_up_city,
        pick_up_state: locationData.pick_up_state,
        pick_up_country: locationData.pick_up_country,
        drop_off_address_line_1: locationData.drop_off_address_line_1,
        drop_off_zipcode: locationData.drop_off_zipcode,
        drop_off_school_id: locationData.drop_off_school_id,
        drop_off_point_id: locationData.drop_off_point_id,
        drop_off_city: locationData.drop_off_city,
        drop_off_state: locationData.drop_off_state,
        drop_off_country: locationData.drop_off_country,
      }),
    });

    if (response.ok) {
      let enrollmentTypeElement = document.getElementById(
        "us-school-enrollment-flow"
      );
      let enrollmentType = enrollmentTypeElement
        ? enrollmentTypeElement.innerHTML.trim()
        : "";
      if (enrollmentType == "classroom") {
        let todayDate = new Date();
        todayDate.setDate(todayDate.getDate());
        todayDate = this.formatDateToString(todayDate);

        this.bookingForm4Target.hidden = true;
        this.bookingFormClassroomProgramPickerTarget.hidden = false;

        let spinner = button.querySelector(".Spinner");
        spinner.classList.add("u-hidden");
        spinner.classList.remove("u-inlineBlock");
        button.disabled = false;
      } else {
        let todayDate = new Date();
        todayDate.setDate(todayDate.getDate());
        todayDate = this.formatDateToString(todayDate);

        let allow_students_to_schedule = document.getElementById(
          "driving-school-rule-allow-students-to-schedule"
        ).innerHTML;
        allow_students_to_schedule = allow_students_to_schedule.trim();

        if (allow_students_to_schedule === "true") {
          console.log("allow_students_to_schedule is true");
          this.bookingForm4Target.hidden = true;
          this.bookingForm5Target.hidden = false;
          this.set_data_attributes_on_weekview();
          this.get_next_slot_day(todayDate, "calendar_next_week", true);
        } else {
          this.bookingForm4Target.hidden = true;
          this.bookingForm1Target.hidden = false;
        }

        // scroll to top
        window.scrollTo(0, 0);

        let spinner = button.querySelector(".Spinner");
        spinner.classList.add("u-hidden");
        spinner.classList.remove("u-inlineBlock");
        button.disabled = false;
      }
      this.refreshInstructorSelection();
    } else {
      let spinner = button.querySelector(".Spinner");
      spinner.classList.add("u-hidden");
      spinner.classList.remove("u-inlineBlock");
      button.disabled = false;
      if (response.status === 404) {
        const data = await response.json();
        // show id: validation-message
        const validationMessage = document.getElementById(
          "location-validation-message"
        );
        validationMessage.hidden = false;

        if (data.pick_up_zone_nil && data.drop_off_zone_nil) {
          document.getElementById("validation-location-type").innerHTML =
            "pick up and drop off";
        } else if (data.pick_up_zone_nil) {
          document.getElementById("validation-location-type").innerHTML =
            "pick up";
        } else if (data.drop_off_zone_nil) {
          document.getElementById("validation-location-type").innerHTML =
            "drop off";
        }
      }
      if (response.status === 403) {
        const validationMessage = document.getElementById(
          "location-different-zone-validation-message"
        );
        validationMessage.hidden = false;
      }
    }
  }
  // selectDay is true if its the inital load of the schedule.
  async get_next_slot_day(start_date, action, selectDay) {
    this.showLoader();
    const csrfToken = document.querySelector('meta[name="csrf-token"]').content;
    const locationData = this.getLocationData();
    const school_token = document
      .getElementById("us-school-token")
      .innerHTML.trim();
    const isAutomaticSelected = optionalChaining(
      () =>
        document.getElementById(
          "driving_school_booking_gearbox_preference_automatic"
        ).checked || false
    );

    // check start_date and make sure it is in the correct format YYYY-MM-DD and it isn't null and it isn't NaN-NaN-NaN
    if (
      start_date == null ||
      start_date == undefined ||
      start_date == "NaN-NaN-NaN" ||
      start_date == "Invalid Date"
    ) {
      alert(
        "The date picked is not valid. Please try again, or contact us if the problem persists."
      );
      return;
    }

    let instructorId = "Any";
    if (this.hasSlotSelectionInstructorIdTarget) {
      instructorId = this.slotSelectionInstructorIdTarget.value;
    }

    const response = await fetch(
      `/us/select-next-slot-day/${school_token}?start_time=${start_date}&week_action=${action}&week_change=${selectDay}&is_automatic=${isAutomaticSelected}&instructor_id=${instructorId}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "X-CSRF-Token": csrfToken,
        },
        body: JSON.stringify({
          driving_school_token: school_token,
          pick_up_category: locationData.office_pickup_category,
          drop_off_category: locationData.office_drop_off_category,
          pick_up_address_line_1: locationData.pick_up_address_line_1,
          pick_up_zipcode: locationData.pick_up_zipcode,
          pick_up_school_id: locationData.pick_up_school_id,
          pick_up_point_id: locationData.pick_up_point_id,
          pick_up_city: locationData.pick_up_city,
          pick_up_state: locationData.pick_up_state,
          pick_up_country: locationData.pick_up_country,
          drop_off_address_line_1: locationData.drop_off_address_line_1,
          drop_off_zipcode: locationData.drop_off_zipcode,
          drop_off_school_id: locationData.drop_off_school_id,
          drop_off_point_id: locationData.drop_off_point_id,
          drop_off_city: locationData.drop_off_city,
          drop_off_state: locationData.drop_off_state,
          drop_off_country: locationData.drop_off_country,
          is_initial_load: selectDay,
        }),
      }
    );
    if (response.ok) {
      const data = await response.json();
      const start_date = data.selected_date;
      const selected_day = data.selected_day;
      const week_start_date = data.week_start_date;
      const disabled_days = data.disabled_days;
      const no_slots_available = data.no_slots_available;
      const time_zone = data.time_zone;

      let date_with_time_zone = start_date
        ? Luxon.DateTime.fromISO(start_date, { zone: time_zone }).startOf("day")
        : Luxon.DateTime.now({ zone: time_zone }).startOf("day");

      if (week_start_date !== null) {
        await this.fetchAndUpdateCalendar(action, week_start_date);
      }
      this.disable_week_days(disabled_days);
      if (start_date !== null && selected_day !== null) {
        this.get_slots(null, date_with_time_zone);
        this.select_slot_day(selected_day);
      }
      this.displayNoSlotAvailable(!no_slots_available);
    } else {
      console.error("Request failed:", response.status);
    }
  }

  displayNoSlotAvailable(future_potentials_slots) {
    const noSlot = document.getElementById("no_slot_available");
    const weekView = document.getElementById("week-view-slots");
    if (future_potentials_slots) {
      if (noSlot) noSlot.hidden = true;
      if (weekView) weekView.hidden = false;
    } else {
      if (noSlot) noSlot.hidden = false;
      if (weekView) weekView.hidden = true;

      const nextButton = document.getElementById("us-booking-next-5");
      if (nextButton) nextButton.disabled = true;
    }
  }

  async disable_previous_week_button(week_start_date) {
    // check if the week_start_date is the same as today or earlier then find the element with the id 'calendar_previous_week' and add class 'Button--disabled' to it else remove the class
    const todayDate = new Date();
    todayDate.setHours(0, 0, 0, 0);
    const weekStartDate = new Date(week_start_date);
    const selectedDayButton = document.getElementById(
      "slots-picker-previous-week-button"
    );

    if (weekStartDate <= todayDate && selectedDayButton) {
      selectedDayButton.classList.add("Button--disabled");
    } else {
      if (selectedDayButton) {
        selectedDayButton.classList.remove("Button--disabled");
      }
    }
  }

  async select_slot_day(selected_day) {
    this.dayButtonTargets.forEach((button) => {
      button.classList.remove("isActive");
    });

    const selectedDayButton = document.getElementById(`day-${selected_day}`);
    selectedDayButton.classList.add("isActive");

    // Set the value of the hidden field with the label of the button that was pressed
    document.getElementById("picked_slot_day").value =
      selectedDayButton.textContent.trim();
    document.getElementById("picked_slot_time").value = "";
  }

  async disable_week_days(disabled_days) {
    disabled_days.forEach((day) => {
      const dayButton = document.getElementById(`day-${day}`);
      if (dayButton) {
        dayButton.classList.add("Button--disabled");
      }
    });
  }

  filter_on_transmission_change() {
    let todayDate = new Date();
    todayDate.setDate(todayDate.getDate());
    todayDate = this.formatDateToString(todayDate);

    this.get_next_slot_day(todayDate, "calendar_next_week", true);
  }

  async next5Pressed(event) {
    this.filterPackagesUsingTransmission();

    event.preventDefault();
    const validationMessage = document.getElementById("validation-message-5");

    const errors = this.validateForm5();

    if (errors.length > 0) {
      validationMessage.innerHTML = errors.join("<br>");
      validationMessage.hidden = false;
    } else {
      validationMessage.hidden = true;

      this.bookingForm5Target.hidden = true;
      this.bookingForm1Target.hidden = false;
    }
  }

  async updatePackages() {
    const school_token = document
      .getElementById("us-school-token")
      .innerHTML.trim();
    const dateOfBirth = this.getDateofBirth();
    console.log(dateOfBirth);

    const location_data = this.getLocationData();

    this.updatedPackagesOnZoneAndDateOfBirth(
      location_data.pick_up_address_line_1,
      location_data.pick_up_zipcode,
      location_data.pick_up_city,
      location_data.pick_up_state,
      location_data.pick_up_country,
      school_token,
      dateOfBirth
    );
  }

  bookingFormClassroomOfficePickerBackButtonPressed(event) {
    this.bookingFormClassroomProgramOfficePickerTarget.hidden = true;
    this.bookingFormClassroomProgramZipcodeTarget.hidden = false;
  }

  bookingFormClassroomProgramPickerBackButtonPressed(event) {
    this.bookingFormClassroomProgramOfficePickerTarget.hidden = false;
    this.bookingFormClassroomProgramPickerTarget.hidden = true;
  }

  back2Pressed(event) {
    event.preventDefault();
    this.bookingForm1Target.hidden = false;
    this.bookingForm2Target.hidden = true;
  }

  back3Pressed(event) {
    event.preventDefault();
    this.bookingForm2Target.hidden = false;
    this.bookingForm3Target.hidden = true;
  }

  back4Pressed(event) {
    event.preventDefault();
    this.bookingForm3Target.hidden = false;
    this.bookingForm4Target.hidden = true;
  }

  back5Pressed(event) {
    event.preventDefault();
    this.bookingForm4Target.hidden = false;
    this.bookingForm5Target.hidden = true;
  }

  back6Pressed(event) {
    event.preventDefault();
    this.bookingForm5Target.hidden = false;
    this.bookingForm6Target.hidden = true;
  }

  isValidDate(dateString) {
    const parts = dateString.split("-"); // Assuming the format is YYYY-MM-DD
    if (parts.length !== 3) {
      return false; // Not a valid format
    }

    const year = parseInt(parts[0], 10);
    const month = parseInt(parts[1], 10) - 1; // Month is 0-indexed in JavaScript Date
    const day = parseInt(parts[2], 10);

    const date = new Date(year, month, day);

    // Check if the date has rolled over to the next month or year, which means the day was invalid
    if (
      date.getFullYear() !== year ||
      date.getMonth() !== month ||
      date.getDate() !== day
    ) {
      return false; // The date rolled over, so it's invalid
    }

    return true;
  }

  getSelectedDate(prefix) {
    var year = document.getElementById(prefix + "_1i").value;
    var month = document.getElementById(prefix + "_2i").value;
    var day = document.getElementById(prefix + "_3i").value;
    return year + "-" + month + "-" + day; // returns a string in "YYYY-MM-DD" format
  }

  getDateFromSelectedDate(prefix) {
    var year = parseInt(document.getElementById(prefix + "_1i").value, 10);
    var month = parseInt(document.getElementById(prefix + "_2i").value, 10) - 1; // months are 0-indexed in JavaScript Date
    var day = parseInt(document.getElementById(prefix + "_3i").value, 10);
    return new Date(year, month, day);
  }

  async validateForm1() {
    const usBookingForm1 = document.getElementById("us-booking-form-1");
    const localizedErrors = JSON.parse(
      usBookingForm1?.dataset?.drivingSchoolBookingFormErrors || "{}"
    );

    let validationErrors = [];

    const isStudentEmailRequired = this.isStudentEmailRequired();
    const isYourEmailRequired = this.isYourEmailRequired();

    // Check the email
    const yourEmailField = document.getElementById("email_value");
    const yourCellField = document.getElementById("cell_number_value");
    console.log(`yourEmailField: ${yourEmailField}`);

    if (yourEmailField && yourCellField) {
      const emailValue = yourEmailField.value || "";
      const emailIsEmpty = emailValue.trim() === "";
      const emailIsValid =
        /^\w+([\.-]?\w+|\+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(emailValue);
      const cellValue = yourCellField.value || "";
      const cellIsEmpty = cellValue.trim() === "";
      const cellIsValid = this.isValidPhoneNumber(cellValue);

      console.log(
        "isYourEmailRequired function returns:",
        this.isYourEmailRequired()
      );
      console.log("isYourEmailRequired variable is:", isYourEmailRequired);
      console.log("emailIsEmpty:", emailIsEmpty);

      if (isYourEmailRequired && cellIsEmpty) {
        const errorMessage = this.getValidationError(
          bookingForm1,
          "cell_number_invalid",
          "Please enter a valid cell number."
        );
        validationErrors.push(errorMessage);
      }

      if (!cellIsEmpty && !cellIsValid) {
        const errorMessage = this.getValidationError(
          bookingForm1,
          "cell_number_invalid",
          "Please enter a valid cell number by removing any brackets, hyphens, fullstops or spaces."
        );
        validationErrors.push(errorMessage);
      }

      if (isYourEmailRequired && emailIsEmpty) {
        const errorMessage = this.getValidationError(
          bookingForm1,
          "required.email",
          "Email address cannot be empty."
        );
        validationErrors.push(errorMessage);
      }

      if (!emailIsEmpty && !emailIsValid) {
        const errorMessage = this.getValidationError(
          bookingForm1,
          "email_invalid",
          "Please enter a valid email address."
        );
        validationErrors.push(errorMessage);
      }

      if (!emailIsEmpty && emailIsValid) {
        const emailTaken = await this.isEmailAlreadyTaken(emailValue);
        const guardianEmailTaken =
          await this.isGuardianSigningUpSelfAndHasEmailTaken(emailValue);

        if (
          guardianEmailTaken &&
          guardianEmailTaken.email_taken &&
          guardianEmailTaken.is_guardian
        ) {
          const guardianPassword = `${this.guardianPasswordTarget.value}` || "";
          const guardianPasswordCorrect = await this.isGuardianPasswordCorrect(
            emailValue,
            guardianPassword
          );
          if (!guardianPasswordCorrect) {
            const errorMessage = this.getValidationError(
              bookingForm1,
              "guardian_email_invalid_password",
              "The password for this parent/guardian email is invalid. Please check your email and password then try again."
            );
            validationErrors.push(errorMessage);
          }
        } else if (emailTaken) {
          const errorMessage = this.getValidationError(
            bookingForm1,
            "email_taken",
            "This email is already taken."
          );
          validationErrors.push(errorMessage);
        }
      }
    }

    const requiredFields = [
      {
        id: "first_name_value",
        name: "First name",
        key: "required.first_name",
      },
      { id: "last_name_value", name: "Last name", key: "required.last_name" },
      { id: "driving_school_booking_gender_female", name: "Gender" },
      { id: "how_did_you_hear_about_us", name: "How did you hear about us" },
      {
        id: "permit_number_value",
        name: "Permit number",
        key: "required.permit",
      },
    ];

    const bookingForm1 = this.bookingForm1Target;

    const guardianDetailsShown =
      document.querySelector("#booking-guardian-details").hidden === false;

    const bookingForOther = document.getElementById(
      "driving_school_booking_enrolling_for_someone_else"
    );
    const studentEmail = document.getElementById("student_email_value");
    const studentPhone = document.getElementById("student_cell_number_value");
    const yourFirstName = document.getElementById("your_first_name_value");
    const yourLastName = document.getElementById("your_last_name_value");
    const guardianEmail = document.getElementById("guardian_email_value");

    if (bookingForOther.checked) {
      if (studentEmail.value.trim() === "" && isStudentEmailRequired) {
        const errorMessage = this.getValidationError(
          bookingForm1,
          "required.student_email",
          "Student email cannot be empty."
        );
        validationErrors.push(errorMessage);
      }
      if (studentPhone.value.trim() === "" && isStudentEmailRequired) {
        const errorMessage = this.getValidationError(
          bookingForm1,
          "required.student_phone",
          "Student cell number cannot be empty."
        );
        validationErrors.push(errorMessage);
      }
      if (yourFirstName.value.trim() === "") {
        const errorMessage = this.getValidationError(
          bookingForm1,
          "required.your_first_name",
          "Your first name cannot be empty."
        );
        validationErrors.push(errorMessage);
      }
      if (yourLastName.value.trim() === "") {
        const errorMessage = this.getValidationError(
          bookingForm1,
          "required.your_last_name",
          "Your last name cannot be empty."
        );
        validationErrors.push(errorMessage);
      }
    } else {
      if (studentEmail.value.trim() === "" && isStudentEmailRequired) {
        const errorMessage = this.getValidationError(
          bookingForm1,
          "required.student_email",
          "Student email cannot be empty."
        );
        validationErrors.push(errorMessage);
      }
    }

    // check student-signature is not empty
    if (this.studentSignaturePad) {
      let studentSignature = this.element.querySelector("#student-signature");
      if (
        this.studentSignaturePad.isEmpty() &&
        studentSignature.classList.contains("u-hidden") == false
      ) {
        const errorMessage = this.getValidationError(
          bookingForm1,
          "signature_empty",
          "Please ensure the student signature is not empty."
        );
        validationErrors.push(errorMessage);
      }
    }

    // check student_email_value and if it is not null or empty, then check if it is a valid email address
    if (studentEmail) {
      if (studentEmail.value.trim() !== "") {
        if (
          !/^\w+([\.-]?\w+|\+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(
            studentEmail.value
          )
        ) {
          const errorMessage = this.getValidationError(
            bookingForm1,
            "student_email_invalid",
            "Please enter a valid email address for student."
          );
          validationErrors.push(errorMessage);
        } else if (isStudentEmailRequired) {
          const encodedEmail = btoa(studentEmail.value);
          try {
            const response = await fetch(`/us/student/${encodedEmail}/taken`);
            const data = await response.json();
            if (data.email_taken === true) {
              const errorMessage = this.getValidationError(
                bookingForm1,
                "student_email_taken",
                "This student email is already taken."
              );
              validationErrors.push(errorMessage);
            }
          } catch (error) {
            console.error("Error:", error);
          }
          // Check student email is not same as other email
          if (
            studentEmail.value ===
              document.getElementById("email_value").value &&
            isStudentEmailRequired
          ) {
            const errorMessage = this.getValidationError(
              bookingForm1,
              "student_email_same",
              "Student email cannot be the same as your email."
            );
            validationErrors.push(errorMessage);
          }
        }
      }
    }

    if (guardianDetailsShown && guardianEmail) {
      if (guardianEmail.value.trim() !== "") {
        if (
          !/^\w+([\.-]?\w+|\+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(
            guardianEmail.value
          )
        ) {
          const errorMessage = this.getValidationError(
            bookingForm1,
            "guardian_email_invalid",
            "Please enter a valid email address for guardian."
          );
          validationErrors.push(errorMessage);
        } else {
          const encodedEmail = btoa(guardianEmail.value);
          try {
            const response = await fetch(`/us/student/${encodedEmail}/taken`);
            const data = await response.json();
            if (data.email_taken === true) {
              const errorMessage = this.getValidationError(
                bookingForm1,
                "guardian_email_taken",
                "This parent/guardian email is already taken."
              );
              validationErrors.push(errorMessage);
              // validationErrors.push(
              //   "This parent/guardian email is already taken."
              // );
            }
          } catch (error) {
            console.error("Error:", error);
          }

          // Check guardian email is not same as other email
          if (
            guardianEmail.value === document.getElementById("email_value").value
          ) {
            const errorMessage = this.getValidationError(
              bookingForm1,
              "student_guardian_email_same",
              "Student and parent/guardian email cannot be the same"
            );
            validationErrors.push(errorMessage);
          }
        }
      }
    }

    for (let field_data of requiredFields) {
      let field = document.getElementById(field_data.id);

      if (field) {
        if (field.value.trim() === "") {
          const errorMessage = this.getValidationError(
            bookingForm1,
            field_data.key,
            `${field_data.name} cannot be empty.`
          );
          validationErrors.push(errorMessage);
        }

        if (
          [
            "driving_school_booking_gender_female",
            "driving_school_booking_gender_male",
            "driving_school_booking_gender_non-binary",
          ].includes(field_data.id)
        ) {
          const gender_female = document.getElementById(
            "driving_school_booking_gender_female"
          );
          const gender_male = document.getElementById(
            "driving_school_booking_gender_male"
          );
          const gender_non_binary = document.getElementById(
            "driving_school_booking_gender_non-binary"
          );

          const genderErrorMessage = this.getValidationError(
            bookingForm1,
            "gender_select",
            "Please select your gender."
          );

          if (gender_non_binary) {
            if (
              !gender_female.checked &&
              !gender_male.checked &&
              !gender_non_binary.checked
            ) {
              validationErrors.push(genderErrorMessage);
            }
          } else {
            if (!gender_female.checked && !gender_male.checked) {
              validationErrors.push(genderErrorMessage);
            }
          }
        }
      }
    }

    // if permit_number_value is visible on the page
    const permitNumber = document.getElementById("permit_number_value");
    if (permitNumber) {
      const issueDateString = this.getSelectedDate(
        "driving_school_booking_permit_issue_date"
      );
      const expiryDateString = this.getSelectedDate(
        "driving_school_booking_permit_expiration"
      );

      if (!this.isValidDate(issueDateString)) {
        const errorMessage = this.getValidationError(
          bookingForm1,
          "permit_issue_date_invalid",
          "Please enter a valid permit issue date."
        );
        validationErrors.push(errorMessage);
      }

      if (!this.isValidDate(expiryDateString)) {
        const errorMessage = this.getValidationError(
          bookingForm1,
          "permit_expiry_date_invalid",
          "Please enter a valid permit expiry date."
        );
        validationErrors.push(errorMessage);
      }

      // this returns a DATE object. If you need a string use getSelectedDate
      let issueDate = this.getDateFromSelectedDate(
        "driving_school_booking_permit_issue_date"
      );
      let expiryDate = this.getDateFromSelectedDate(
        "driving_school_booking_permit_expiration"
      ); // Adjust the prefix based on your expiry date fields' IDs

      if (issueDate > expiryDate) {
        const errorMessage = this.getValidationError(
          bookingForm1,
          "permit_expiry_before_issue",
          "The permit expiry date cannot be before the issue date."
        );
        validationErrors.push(errorMessage);
      }

      if (issueDateString === expiryDateString) {
        const errorMessage = this.getValidationError(
          bookingForm1,
          "permit_expiry_same_as_issue",
          "The permit expiry date cannot be the same as the issue date."
        );
        validationErrors.push(errorMessage);
      }
    }

    let dateOfBirth = this.getSelectedDate(
      "driving_school_booking_date_of_birth"
    );

    if (!this.isValidDate(dateOfBirth)) {
      const errorMessage = this.getValidationError(
        bookingForm1,
        "date_of_birth_invalid",
        "Please enter a valid date of birth."
      );
      validationErrors.push(errorMessage);
    }

    return validationErrors;
  }

  isValidPhoneNumber(phoneNumberString) {
    const regex = /^\+?(\d{1,3}-?){0,2}\d{1,3}-?\d{1,4}$/;
    const hyphenCount = (phoneNumberString.match(/-/g) || []).length;
    const digitCount = phoneNumberString.replace(/\D/g, "").length;
    return (
      regex.test(phoneNumberString) &&
      hyphenCount <= 2 &&
      digitCount >= 10 &&
      digitCount <= 13
    );
  }

  goToSignIn() {
    window.location.href = "/users/sign_in";
  }

  dismissDuplicateEmailAlert() {
    this.duplicateEmailAlertTarget.hidden = true;
    this.guardianEmailAlreadyTakenAlertTarget.hidden = true;
  }

  /**
   * This function is called to refresh the instructor selection dropdown
   */
  async refreshInstructorSelection() {
    const csrfToken = document.querySelector('meta[name="csrf-token"]').content;
    const school_token = document
      .getElementById("us-school-token")
      .innerHTML.trim();
    const locationData = this.getLocationData();

    let idOfSlotSelectionInstructorIdTarget = "";
    if (this.hasSlotSelectionInstructorIdTarget) {
      idOfSlotSelectionInstructorIdTarget =
        this.slotSelectionInstructorIdTarget.id;
    }

    const response = await fetch(
      `/us/slots/instructor-list-for-student-booking`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "X-CSRF-Token": csrfToken,
        },
        body: JSON.stringify({
          driving_school_token: school_token,
          pick_up_category: locationData.office_pickup_category,
          drop_off_category: locationData.office_drop_off_category,
          pick_up_address_line_1: locationData.pick_up_address_line_1,
          pick_up_zipcode: locationData.pick_up_zipcode,
          pick_up_school_id: locationData.pick_up_school_id,
          pick_up_point_id: locationData.pick_up_point_id,
          pick_up_city: locationData.pick_up_city,
          pick_up_state: locationData.pick_up_state,
          pick_up_country: locationData.pick_up_country,
          drop_off_address_line_1: locationData.drop_off_address_line_1,
          drop_off_zipcode: locationData.drop_off_zipcode,
          drop_off_school_id: locationData.drop_off_school_id,
          drop_off_point_id: locationData.drop_off_point_id,
          drop_off_city: locationData.drop_off_city,
          drop_off_state: locationData.drop_off_state,
          drop_off_country: locationData.drop_off_country,
          item_selected_function:
            "driving-school-booking-form#changeInstructor",
          input_query_selector: `#${idOfSlotSelectionInstructorIdTarget}`,
        }),
      }
    );

    if (!response.ok) {
      alert("Error refreshing instructor selection");
      return;
    }

    if (response.ok) {
      const responseBody = await response.text();
      const parser = new DOMParser();
      const xmlDoc = parser.parseFromString(responseBody, "text/html");
      const template = xmlDoc.querySelector("template");
      const targetElement = document.getElementById(
        "driving_school_admin_booking_form_v2_instructor_selector"
      );

      if (template && targetElement) {
        targetElement.innerHTML = "";
        targetElement.appendChild(template.content.cloneNode(true));
      }
    } else {
      alert("Something went wrong");
    }
  }

  guardianEmailChangedIdForThrottling = null;
  async guardianEmailChanged(event) {
    // Function reserved for future use
  }

  emailChangedIdForThrottling = null;
  async emailChanged(event) {
    this.dismissDuplicateEmailAlert();
    if (event) event.preventDefault();

    const emailValue = document
      .getElementById("email_value")
      .value.toLowerCase();

    const randomId = Math.random().toString(36).substring(7);
    this.emailChangedIdForThrottling = randomId;
    await new Promise((resolve) => setTimeout(resolve, 1000));
    // If the id has changed, then another request has been made and this one is no longer valid
    if (this.emailChangedIdForThrottling !== randomId) return;

    const isStudentSigningUpSelfAndHasEmailTaken =
      await this.isStudentSigningUpSelfAndHasEmailTaken(emailValue);
    if (isStudentSigningUpSelfAndHasEmailTaken === true) {
      this.duplicateEmailAlertTarget.hidden = false;
      return;
    }

    const isGuardianSigningUpSelfAndHasEmailTaken =
      await this.isGuardianSigningUpSelfAndHasEmailTaken(emailValue);

    if (
      isGuardianSigningUpSelfAndHasEmailTaken &&
      isGuardianSigningUpSelfAndHasEmailTaken.email_taken &&
      isGuardianSigningUpSelfAndHasEmailTaken.is_guardian
    ) {
      this.guardianEmailAlreadyTakenAlertTarget.hidden = false;
      return;
    }

    if (
      isGuardianSigningUpSelfAndHasEmailTaken &&
      isGuardianSigningUpSelfAndHasEmailTaken.email_taken &&
      !isGuardianSigningUpSelfAndHasEmailTaken.is_guardian
    ) {
      this.duplicateEmailAlertTarget.hidden = false;
      return;
    }
  }

  async isGuardianSigningUpSelfAndHasEmailTaken(email) {
    const isSelfSignUp = document.getElementById(
      "driving_school_booking_enrolling_for_myself"
    ).checked;
    if (isSelfSignUp) return false;
    const isEmailAlreadyTaken = await this.isGuardianEmailAlreadyTaken(email);
    return isEmailAlreadyTaken;
  }

  async isGuardianPasswordCorrect(email, password) {
    const encodedEmail = btoa(email);
    try {
      const response = await fetch(
        `/us/student/${encodedEmail}/guardian_login`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            password: password,
          }),
        }
      );
      const data = await response.json();
      return !!data.success;
    } catch (error) {
      return false;
    }
  }

  async isStudentSigningUpSelfAndIsUnderageAndHasGuardianEmailTaken(email) {
    const isSelfSignUp = document.getElementById(
      "driving_school_booking_enrolling_for_myself"
    ).checked;
    if (!isSelfSignUp) return false;
    const age = this.calculateAge();
    const isUnderage = age < 18;
    if (!isUnderage) return false;

    const isEmailAlreadyTaken = await this.isGuardianEmailAlreadyTaken(email);
    return isEmailAlreadyTaken;
  }

  async isStudentSigningUpSelfAndHasEmailTaken(email) {
    const isSelfSignUp = document.getElementById(
      "driving_school_booking_enrolling_for_myself"
    ).checked;
    if (!isSelfSignUp) return false;
    const isEmailAlreadyTaken = await this.isEmailAlreadyTaken(email);
    return isEmailAlreadyTaken;
  }

  async isGuardianEmailAlreadyTaken(email) {
    const encodedEmail = btoa(email);
    try {
      const response = await fetch(
        `/us/student/${encodedEmail}/guardian_taken`
      );
      const data = await response.json();
      return {
        email_taken: data.email_taken,
        is_guardian: data.is_guardian,
      };
    } catch (error) {
      console.error("Error:", error);
      return {
        email_taken: undefined,
        is_guardian: undefined,
      };
    }
  }

  async isEmailAlreadyTaken(email) {
    const encodedEmail = btoa(email);
    try {
      const response = await fetch(`/us/student/${encodedEmail}/taken`);
      const data = await response.json();
      return !!data.email_taken;
    } catch (error) {
      console.error("Error:", error);
      return undefined;
    }
  }

  emailCheckIdForThrottling = null;
  async isEmailAlreadyTakenWithThrottle(email) {
    const randomId = Math.random().toString(36).substring(7);
    this.emailCheckIdForThrottling = randomId;
    await new Promise((resolve) => setTimeout(resolve, 1000));
    // If the id has changed, then another request has been made and this one is no longer valid
    // We return undefined to indicate that the result is ambiguous
    if (this.emailCheckIdForThrottling !== randomId) return undefined;
    const encodedEmail = btoa(email);
    try {
      const response = await fetch(`/us/student/${encodedEmail}/taken`);
      const data = await response.json();
      return !!data.email_taken;
    } catch (error) {
      console.error("Error:", error);
      return undefined;
    }
  }

  validateForm3() {
    let validationErrors = [];

    const requiredFields = [
      {
        id: "address_line_1_value",
        name: "Address line 1",
        key: "required.address",
      },
      { id: "zipcode_value", name: "Zipcode", key: "required.zipcode" },
      { id: "city_id", name: "City", key: "required.city" },
      { id: "state_id", name: "State", key: "required.state" },
    ];

    const bookingForm3 = this.bookingForm3Target;

    requiredFields.forEach((field_data) => {
      let field = document.getElementById(field_data.id);

      if (field) {
        if (field.value.trim() === "") {
          const errorMessage = this.getValidationError(
            bookingForm3,
            field_data.key,
            `${field_data.name} cannot be empty.`
          );
          validationErrors.push(errorMessage);
        }
        if (
          field_data.id === "zipcode_value" &&
          field.value.trim().length != 5
        ) {
          const errorMessage = this.getValidationError(
            bookingForm3,
            "zipcode_length",
            `${field_data.name} must be 5 numbers.`
          );
          validationErrors.push(errorMessage);
        }
      }
    });

    return validationErrors;
  }

  validateForm5() {
    let validationErrors = [];

    const requiredFields = [{ id: "picked_slot_time", name: "Lesson slot" }];

    requiredFields.forEach((field_data) => {
      let field = document.getElementById(field_data.id);

      if (field) {
        if (field.value.trim() === "") {
          const next5Button = this.nextButton5Target;
          const slotError =
            next5Button?.dataset?.drivingSchoolBookingFormSlotError ||
            "You must pick a lesson slot";
          validationErrors.push(slotError);
        }
      }
    });

    return validationErrors;
  }

  validateForm6() {
    let validationErrors = [];

    const requiredFields = [{ id: "picked_slot_time", name: "Lesson slot" }];

    requiredFields.forEach((field_data) => {
      let field = document.getElementById(field_data.id);

      if (field) {
        if (field.value.trim() === "") {
          validationErrors.push(`You must pick a lesson slot`);
        }
      }
    });

    return validationErrors;
  }

  validateForm6() {
    let validationErrors = [];

    const radioButtons = this.radioTargets;
    let isChecked = false;

    for (let i = 0; i < radioButtons.length; i++) {
      if (radioButtons[i].checked) {
        isChecked = true;
        break;
      }
    }

    if (!isChecked) {
      validationErrors.push(`You must pick a package`);
    }

    return validationErrors;
  }

  filterPackagesUsingTransmission() {
    // const isAutomaticSelected = this.automaticRadioTarget.checked;
    // const isManualSelected = this.manualRadioTarget.checked;

    const isAutomaticSelected = optionalChaining(
      () =>
        document.getElementById(
          "driving_school_booking_gearbox_preference_automatic"
        ).checked || false
    );
    const isManualSelected = optionalChaining(
      () =>
        document.getElementById(
          "driving_school_booking_gearbox_preference_manual"
        ).checked || false
    );

    document
      .querySelectorAll("[data-driving-school-booking-form-is-automatic]")
      .forEach((item) => {
        item.hidden = true;
      });

    document
      .querySelectorAll("[data-driving-school-booking-form-is-manual]")
      .forEach((item) => {
        item.hidden = true;
      });

    if (isAutomaticSelected) {
      document
        .querySelectorAll(
          "[data-driving-school-booking-form-is-automatic='yes']"
        )
        .forEach((item) => {
          item.hidden = false;
        });
    }

    if (isManualSelected) {
      document
        .querySelectorAll("[data-driving-school-booking-form-is-manual='yes']")
        .forEach((item) => {
          item.hidden = false;
        });
    }
  }

  beginListeningForLocationChanges() {
    if (this.hasBookingForm4Target) {
      let el = this.bookingForm4Target;
      this.listenForValueAndDomChangesOnAndWithinElement(el, () => {
        this.set_data_attributes_on_weekview();
      });
    }
  }

  listenForValueAndDomChangesOnAndWithinElement(element, onChangeFunction) {
    if (!element) {
      return;
    }

    // create an observer instance
    const mutationObserver = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        onChangeFunction();
      });
    });
    // Configure the MutationObserver to listen for attribute changes
    const observerConfiguration = {
      attributes: true,
      childList: true,
      characterData: true,
      subtree: true,
    };
    // Observe all mutations within the element
    mutationObserver.observe(element, observerConfiguration);

    // Get all elements within the target element
    const allElementsWithinTarget = element.getElementsByTagName("*");
    // To all elements within the target element, add an event listener
    for (let i = 0; i < allElementsWithinTarget.length; i++) {
      const elementWithinTarget = allElementsWithinTarget[i];
      // Add an event listener to the element
      elementWithinTarget.addEventListener("input", (event) => {
        if (event.target instanceof HTMLInputElement) {
          onChangeFunction();
        }
      });
    }
  }

  getLocale() {
    return this.localeTarget ? this.localeTarget.value : "en";
  }

  getAddressInvalidError() {
    const next3Button = this.nextButton3Target;
    return (
      next3Button?.dataset?.drivingSchoolBookingFormAddressError ||
      "The address you entered is not valid or is not covered by this school. Please try again."
    );
  }

  getValidationError(form, key, fallbackMessage) {
    const localizedErrors = JSON.parse(
      form?.dataset?.drivingSchoolBookingFormErrors || "{}"
    );

    if (key.includes(".")) {
      const [parentKey, subKey] = key.split(".");
      return localizedErrors?.[parentKey]?.[subKey] || fallbackMessage;
    }

    return localizedErrors?.[key] || fallbackMessage;
  }
}

// Helper function to get meta value
function getMetaValue(name) {
  const element = document.head.querySelector(`meta[name="${name}"]`);
  return element.getAttribute("content");
}

function optionalChaining(valueFn) {
  try {
    return valueFn();
  } catch (e) {
    return undefined;
  }
}

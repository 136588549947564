// driving_school_classroom_program_setup_controller.js
import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    "dayCheckbox",
    "descriptionEnglish",
    "descriptionSpanish",
    "locationTypeInPerson",
    "locationTypeRemote",
    "locationTypeOptionInPerson",
    "locationTypeOptionRemote",
    "locationTypeOptionBoth",
    "languageTypeOptionBoth",
    "languageTypeOptionEnglish",
    "languageTypeOptionSpanish",
    "gapBetweenSessions",
    'instructorIds',
    "startTime", 
    "dateSelector", 
    "timeSelector"
  ]

  updateInstructorIdValues(event) {
    this.instructorIdsTarget.value = `[${event.detail.dispatchItem.id}]`;
  }

  updateStartTime() {
    if (this.dateSelectorTarget.value && this.timeSelectorTarget.value) {
      this.startTimeTarget.value = `${this.dateSelectorTarget.value}T${this.timeSelectorTarget.value}`
    }
  }

  updateSessionsPerDay(event) {
    // check the value of the thing calling function
    const sessionsPerDay = event.target.value;
    if (sessionsPerDay === "1") {
      this.gapBetweenSessionsTarget.classList.add("u-hidden");
    } else {
      this.gapBetweenSessionsTarget.classList.remove("u-hidden");
    }
  }

  updateLanguageOption() {
    if (this.languageTypeOptionBothTarget.checked) {
      // show both descriptionEnglish and descriptionSpanish
      this.showBoth();
      return
    }

    if (this.languageTypeOptionEnglishTarget.checked) {
      this.showEnglish();
      return
    }

    if (this.languageTypeOptionSpanishTarget.checked) {
      this.showSpanish();
      return
    }
  }
  updateLocationType() {
    if (this.locationTypeOptionInPersonTarget.checked) {
      // locationTypeInPerson show this hide remote
      this.locationTypeInPersonTarget.classList.remove("u-hidden");
      this.locationTypeRemoteTarget.classList.add("u-hidden");
    }

    if (this.locationTypeOptionRemoteTarget.checked) {
      this.locationTypeInPersonTarget.classList.add("u-hidden");
      this.locationTypeRemoteTarget.classList.remove("u-hidden");
    }

    if (this.locationTypeOptionBothTarget.checked) {
      this.locationTypeInPersonTarget.classList.remove("u-hidden");
      this.locationTypeRemoteTarget.classList.remove("u-hidden");
    }
  }

  updateVisibility() {
    const selectedLanguage = document.querySelector('input[name="language"]:checked').value;
    if (selectedLanguage === 'en') {
      this.showEnglish();
    } else if (selectedLanguage === 'es') {
      this.showSpanish();
    } else {
      this.showBoth();
    }
  }

  showEnglish() {
    this.descriptionEnglishTargets.forEach(target => {
      target.classList.remove("u-hidden");
    });
    this.descriptionSpanishTargets.forEach(target => {
      target.classList.add("u-hidden");
    });
  }

  showSpanish() {
    this.descriptionEnglishTargets.forEach(target => {
      target.classList.add("u-hidden");
    });
    this.descriptionSpanishTargets.forEach(target => {
      target.classList.remove("u-hidden");
    });
  }

  showBoth() {
    this.descriptionEnglishTargets.forEach(target => {
      target.classList.remove("u-hidden");
    });
    this.descriptionSpanishTargets.forEach(target => {
      target.classList.remove("u-hidden");
    });
  }

  validate(event) {
    const isEdit = event.currentTarget.dataset.edit === "true";
    const errors = this.validateForm(isEdit);
    
    if (errors.length > 0) {
      event.preventDefault();
      this.showValidationBlock(errors.join("<br>"));
      return;
    }    
  }

  validateForm(isEdit) {
    let validationErrors = [];

    let fieldsToValidate = isEdit ? [] : [
      { id: "driving_school_classroom_program_start_time", name: "Start time", validation: "required|datetime" },
      { id: "driving_school_classroom_program_number_of_sessions", name: "Total sessions", validation: "required|positiveNumber" },
      { id: "driving_school_classroom_program_number_of_sessions_per_day", name: "Sessions per day", validation: "required|positiveNumber" },
      { 
        id: "driving_school_classroom_program_gap_between_sessions_in_minutes", 
        name: "Session gap (mins)", 
        hidden: this.gapBetweenSessionsTarget.classList.contains('u-hidden'),
        validation: "required|positiveNumber" 
      },
      { type: "daysSessionsRun" },
      { id: "driving_school_classroom_program_instructor_ids", name: "Instructor", validation: "required" },
      { 
        id: "driving_school_classroom_program_driving_school_office_id", 
        name: "Location", 
        validation: "required|positiveNumber",
        hidden: this.locationTypeInPersonTarget.classList.contains('u-hidden')
      },
      {
        id: "driving_school_classroom_program_max_number_of_in_person_attendees", 
        name: "Max attendees", 
        hidden: this.locationTypeInPersonTarget.classList.contains('u-hidden'), 
        validation: "required|positiveNumber" 
      },
      { 
        id: "driving_school_classroom_program_max_number_of_remote_attendees", 
        name: "Max remote attendees", 
        hidden: this.locationTypeRemoteTarget.classList.contains('u-hidden'), 
        validation: "required|positiveNumber" 
      }
    ];

    fieldsToValidate.forEach(field_data => {
      if (!field_data.hidden) {
        if (field_data.type === "daysSessionsRun") {
          let selectedDays = this.dayCheckboxTargets.filter(checkbox => checkbox.checked).map(checkbox => checkbox.value);
          let daysSessionsRun = selectedDays.map(day => day.toLowerCase()).join(", ");
    
          if (daysSessionsRun === "") {
            validationErrors.push("At least one day must be selected for sessions.");
          }
        } else {
          let field = document.getElementById(field_data.id);
          
          if (field) {
            let value = field.value;
            let validationRules = field_data.validation.split('|');
      
            for (let rule of validationRules) {
              switch (rule) {
                case 'required':
                  if (!value) {
                    if (field_data.name === "Instructor") {
                      validationErrors.push("An instructor must be selected.");
                    } else {
                      validationErrors.push(`${field_data.name} is required.`);
                    }
                    return;
                  }
                  break;
      
                case 'datetime':
                  if (value && isNaN(Date.parse(value))) {
                    validationErrors.push(`${field_data.name} must be a valid date-time.`);
                    return;
                  }
                  break;
      
                case 'positiveNumber':
                  if (value === "") {
                    validationErrors.push(`${field_data.name} cannot be empty.`);
                    return;
                  } else if (isNaN(value) || Number(value) <= 0) {
                    validationErrors.push(`${field_data.name} must be greater than 0.`);
                    return;
                  }
                  break;
              }
            }
          }
        }
      }
    });
    
    return validationErrors;
  }

  showValidationBlock(message) {
    const validationBlock = document.getElementById("validation-block");
    const validationMessage = document.getElementById("validation-message");
  
    validationMessage.innerHTML = message;
    validationBlock.hidden = false;
    validationMessage.hidden = false;
  }
}
